import Config from 'react-native-config';
const GOOGLE_AUTH_CONFIG = {
  offlineAccess: true,
  webClientId: Config.WEB_CLIENT_ID,
  iosClientId: Config.IOS_CLIENT_ID,
};
export { GOOGLE_AUTH_CONFIG };

export class DynamicLinkType {
  public static readonly PARENT = 'parent';
  public static readonly CHILD = 'child';
}

export class RegexValidation {
  public static readonly NUMBER_ONLY = '^[0-9]+$';
  public static readonly NAME = "^[a-zA-Z0-9áéíóúÁÉÍÓÚ '._-]+$";
}

export class GeneralDynamicConfig {
  public static readonly SEGMENT_STATUS = {
    segments: [
      { name: 'Active', value: 'Active' },
      { name: 'Inactive', value: 'InActive' },
      { name: 'Decommissioned', value: 'Decommissioned' },
    ],
  };

  public static readonly SEGMENT_OWNER = {
    segments: [
      { name: 'Rental', value: 'Rental' },
      { name: 'Owned', value: 'Owned' },
      { name: 'Personal', value: 'Personal' },
    ],
  };
}

export class FilterTypes {
  public static readonly DATE_RANGE = 'date-range';
  public static readonly TASK_STATUS = 'task-status';
  public static readonly REPORT_TYPE = 'report-type';
  public static readonly PERSON = 'person';
  public static readonly PROJECT = 'project';
  public static readonly EQUIPMENT = 'equipment';
  public static readonly INVESTIGATION = 'investigation';
}
