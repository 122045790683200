import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Image, Pressable, Modal } from 'react-native';
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useSync } from '../../Providers/SyncProvider';
import { ObjectId } from 'bson';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import ConfirmModal from '../Shared/ConfirmModal';
import Icon from '../Shared/Icon';
import {
  DynamicPeopleListProps,
  DynamicSignatureListProps,
} from '../../Types/ControlTypes';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import Colors from '../../Styles/Shared/Colors';
import DynamicTextArea from './DynamicTextArea';

export declare type PeopleListItem = {
  index: number;
  person: string;
  personName: string;
  description: string;
};

const DynamicPeopleList = (
  props: DynamicPeopleListProps,
): React.ReactElement => {
  const [value, setValue] = useState(props.value ?? '');
  const [items, setItems] = useState<PeopleListItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<PeopleListItem | null>(null);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const [itemToRemove, setiIemToRemove] = useState<PeopleListItem | null>(null);
  const [error, setError] = useState('');
  const [showModalErrors, setShowModalErrors] = useState(false);

  const { getPeople } = useSync();

  const deleteModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  useEffect(() => {
    try {
      if (value) {
        let newItems = JSON.parse(value) as PeopleListItem[];
        if (newItems) setItems(newItems);
      }
    } catch (error) {
      console.log('Error: Bad People List value serialization');
    }
  }, []);

  useEffect(() => {
    if (props.onChange)
      props.onChange(props.controlId, props.controlTypeId, value, valid());
  }, [value]);

  useEffect(() => {
    if (props.showError) valid();
  }, [props.showError]);

  function getNewItem(): PeopleListItem {
    let nextIndex = 0;
    if (items && items.length > 0)
      nextIndex = Math.max(...items.map(o => o.index)) + 1;

    let newItem: PeopleListItem = {
      index: nextIndex,
      person: '',
      personName: '',
      description: '',
    };

    return newItem;
  }

  async function getPersonName(itemValue: string): Promise<string> {
    let personName = '';

    let valObj:
      | {
          mongoId: string;
          SQLServerId: string;
        }
      | undefined = undefined;

    try {
      valObj = JSON.parse(itemValue) as {
        mongoId: string;
        SQLServerId: string;
      };

      if (valObj) {
        let person = (
          await getPeople({ _id: new ObjectId(valObj!.mongoId) }, 1)
        )[0];
        if (person) personName = person.firstName + ' ' + person.lastName;
      }
    } catch (error) {
      console.log('Error: Bad People List value serialization');
    }

    return personName;
  }

  function showModal(item: PeopleListItem | null): void {
    if (props.disabled) return;

    setSelectedItem(item ? { ...item } : getNewItem());
    setIsShowingModal(true);
    setShowModalErrors(false);
  }

  function hideModal(): void {
    setIsShowingModal(false);
  }

  async function changePerson(person: string, isValid: boolean): Promise<void> {
    let currentItem = { ...selectedItem! };

    if (isValid) {
      currentItem.person = person;
      currentItem.personName = await getPersonName(person);
    } else {
      currentItem.person = '';
      currentItem.personName = '';
    }

    setSelectedItem(currentItem);
  }

  function changeDescription(description: string, isValid: boolean): void {
    let currentItem = { ...selectedItem! };

    if (isValid) currentItem.description = description;
    else currentItem.description = '';

    setSelectedItem(currentItem);
  }

  function save(): void {
    // Validations
    if (selectedItem!.person == '' || selectedItem!.description == '') {
      setShowModalErrors(true);
      return;
    }

    let currentItem = { ...selectedItem! };

    let itemsArray = [...items];
    let itemIndex = itemsArray.findIndex(i => i.index === currentItem.index);
    if (itemIndex !== -1) {
      itemsArray[itemIndex].person = currentItem.person;
      itemsArray[itemIndex].personName = currentItem.personName;
      itemsArray[itemIndex].description = currentItem.description;
    } else itemsArray.push(currentItem);

    setItems(itemsArray);
    setValue(JSON.stringify(itemsArray));
    setIsShowingModal(false);
  }

  function remove(item: PeopleListItem): void {
    setiIemToRemove(item);
    deleteModalCommand.current.openModal();
  }

  function confirmRemove(): void {
    let newItems = [...items];

    let arrayIndex = newItems.indexOf(itemToRemove!);
    if (arrayIndex !== -1) {
      newItems.splice(arrayIndex, 1);
      setItems(newItems);
      setValue(JSON.stringify(newItems));
      setiIemToRemove(null);
    }
  }

  function expand(item: PeopleListItem): void {
    let newExpandedItems = [...expandedItems];

    newExpandedItems.push(item.index);

    setExpandedItems(newExpandedItems);
  }

  function collapse(item: PeopleListItem): void {
    let newExpandedItems = [...expandedItems];

    let arrayIndex = newExpandedItems.indexOf(item.index);
    if (arrayIndex !== -1) {
      newExpandedItems.splice(arrayIndex, 1);
      setExpandedItems(newExpandedItems);
    }
  }

  function valid(): boolean {
    let isValid = true;
    let error = '';

    if (props.config?.required && (value === '' || value === '[]')) {
      isValid = false;
      error = (props.label ?? 'This field') + ' is required';
    }

    if (props.showError) setError(error);

    return isValid;
  }

  const renderSignatureList = (): React.ReactElement => {
    return (
      <View style={{ marginVertical: 12 }}>
        {items.map(item => {
          return (
            <View
              key={item.index}
              style={[
                ReportsStyleSheet.listItemContainer,
                {
                  flexWrap: 'wrap',
                  borderWidth: 1,
                  borderColor: Colors.darkGreen,
                  borderRadius: 12,
                  padding: 12,
                  marginBottom: 12,
                },
              ]}>
              <Text
                style={[
                  ReportsStyleSheet.listItemName,
                  { paddingHorizontal: 12, paddingTop: 4 },
                ]}>
                {item.personName}
              </Text>
              {!props.disabled && (
                <React.Fragment>
                  <Pressable
                    style={({ pressed }) => [
                      {
                        width: 28,
                        height: 28,
                        borderRadius: 28,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginHorizontal: 12,
                      },
                      pressed && {
                        backgroundColor: Colors.darkGreenTransparent,
                      },
                    ]}
                    disabled={props.disabled}
                    onPress={() => remove(item)}>
                    <Icon
                      icon={'trash'}
                      color={Colors.darkestGreen}
                      size={20}
                    />
                  </Pressable>
                  <Pressable
                    style={({ pressed }) => [
                      {
                        width: 28,
                        height: 28,
                        borderRadius: 28,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginHorizontal: 12,
                      },
                      pressed && {
                        backgroundColor: Colors.darkGreenTransparent,
                      },
                    ]}
                    disabled={props.disabled}
                    onPress={() => showModal(item)}>
                    <Icon
                      icon={'pencil'}
                      color={Colors.darkestGreen}
                      size={20}
                    />
                  </Pressable>
                </React.Fragment>
              )}
              {!expandedItems.includes(item.index) && (
                <Pressable
                  style={({ pressed }) => [
                    {
                      width: 28,
                      height: 28,
                      borderRadius: 28,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginHorizontal: 6,
                    },
                    pressed && {
                      backgroundColor: Colors.darkGreenTransparent,
                    },
                  ]}
                  onPress={() => expand(item)}>
                  <Icon icon={'expand'} color={Colors.darkestGreen} size={20} />
                </Pressable>
              )}
              {expandedItems.includes(item.index) && (
                <React.Fragment>
                  <Pressable
                    style={({ pressed }) => [
                      {
                        width: 28,
                        height: 28,
                        borderRadius: 28,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginHorizontal: 6,
                      },
                      pressed && {
                        backgroundColor: Colors.darkGreenTransparent,
                      },
                    ]}
                    onPress={() => collapse(item)}>
                    <Icon
                      icon={'collapse'}
                      color={Colors.darkestGreen}
                      size={20}
                    />
                  </Pressable>
                  <View style={{ flexBasis: '100%', paddingHorizontal: 12 }}>
                    <Text
                      style={[
                        ControlsStyleSheet.label,
                        { fontSize: 14, textTransform: 'none' },
                      ]}>
                      {item.description}
                    </Text>
                  </View>
                </React.Fragment>
              )}
            </View>
          );
        })}
      </View>
    );
  };

  const renderModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {props.label}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={hideModal}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <View>
              <View style={{ marginTop: 12 }}>
                <DynamicAutocomplete
                  label="Name"
                  config={{
                    optionSource: ListDataTypes.PEOPLE,
                    required: true,
                  }}
                  value={selectedItem?.person ?? ''}
                  onChange={(controlId, controlTypeId, value, isValid) =>
                    changePerson(value, isValid)
                  }
                  showError={showModalErrors}
                />
              </View>
              <View>
                <DynamicTextArea
                  label={props.config?.descriptionLabel ?? 'Description'}
                  config={{
                    placeholder:
                      props.config?.descriptionLabel ?? 'Description',
                    required: true,
                  }}
                  value={selectedItem?.description ?? ''}
                  onChange={(controlId, controlTypeId, value, isValid) =>
                    changeDescription(value, isValid)
                  }
                  showError={showModalErrors}
                />
              </View>
            </View>
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.greenButton,
                { alignSelf: 'center', marginTop: 'auto', marginBottom: 24 },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              onPress={save}>
              <Text style={CommonStyleSheet.greenButtonText}>Save</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <View style={{ display: props.visible === false ? 'none' : 'flex' }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 24,
        }}>
        <Text style={ControlsStyleSheet.labelLarge}>
          {props.label}
          <Text
            style={[
              ControlsStyleSheet.required,
              { fontSize: 16, verticalAlign: 'middle' },
            ]}>
            {props.config?.required ? '*' : ''}
          </Text>
        </Text>
        <Pressable
          style={({ pressed }) => [
            ControlsStyleSheet.linkRecordAddButton,
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          disabled={props.disabled}
          onPress={() => showModal(null)}>
          <Icon icon={'plus'} color={Colors.darkestGreen} size={24} />
        </Pressable>
      </View>
      {renderSignatureList()}
      {renderModal()}
      <Text style={ControlsStyleSheet.error}>{error}</Text>
      <ConfirmModal
        title="Remove this person"
        message="Are you sure you want to remove this person?"
        cancelClick={() => {}}
        cancelText="No"
        okClick={() => {
          confirmRemove();
        }}
        okText="Remove"
        setOpenModal={(func: () => void) => {
          deleteModalCommand.current.setOpenModal(func);
        }}
      />
    </View>
  );
};

export default DynamicPeopleList;
