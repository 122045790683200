import React, { useState, useEffect } from 'react';
import { ScrollView, View, Image, Text, Pressable, Modal } from 'react-native';
import moment from 'moment';
import { ObjectId } from 'bson';
import { useIsFocused } from '@react-navigation/native';
import { useSync } from '../../Providers/SyncProvider';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import DynamicCheckBox from '../DynamicControls/DynamicCheckBox';
import DynamicPDF from '../DynamicForm/DynamicPDF';
import CustomPressable from '../Shared/CustomPressable';
import SearchBox from '../Shared/SearchBox';
import Icon from '../Shared/Icon';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import Colors from '../../Styles/Shared/Colors';
import { Icons } from '../../Constants/Icons';
import { Navigation } from '../../Constants/Navigation';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { Formats } from '../../Constants/Formats';
import {
  LOCK_STATUS,
  METADATA_KEYS,
  TRAINING_TYPES,
} from '../../Constants/AppConstants';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';

export declare type TrainingBreakdownProps = {
  navigation: any;
};

export type RecordType = {
  id: ObjectId;
  name: string;
  source: 'training' | 'certificate';
};

const statuses = ['Valid', 'Expiring Soon', 'Expired', 'Never Expires'];
type Status = (typeof statuses)[number];
export type Record = {
  id: ObjectId;
  person: { id?: ObjectId; name?: string };
  status: Status;
  expiryDate: Date | undefined;
  type: RecordType;
  icon: string;
  isLocked?: boolean | undefined;
};

const TrainingBreakdown = (
  props: TrainingBreakdownProps,
): React.ReactElement => {
  const [search, setSearch] = useState('');
  const [recordTypes, setRecordTypes] = useState<RecordType[]>([]);
  const [records, setRecords] = useState<Record[]>([]);
  const isFocused = useIsFocused();

  const [selectedRecord, setSelectedRecord] = useState<Record>();
  const [isShowingPDFModal, setIsShowingPDFModal] = useState(false);
  const [recordToPDF, setRecordToPDF] = useState<Record>();

  const [filteredPerson, setFilteredPerson] = useState('');
  const [filteredStatus, setFilteredStatus] = useState<Status[]>([...statuses]);
  const [filteredTypes, setFilteredTypes] = useState<string[]>([]);

  const DISPLAY_INCREMENT = 5;
  const [recordsToShow, setRecordsToShow] = useState<
    { typeIndex: number; recordsToShow: number }[]
  >([]);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const { getSubmissionTrainings, getTrainingTypes, getCertificationTypes } =
    useSync();

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    if (isFocused) fetchRecords();
  }, [isFocused]);

  const fetchRecords = async () => {
    let trainingTypesArray: RecordType[] = (await getTrainingTypes()).map(
      t => ({
        id: t._id,
        name: t.name ?? '',
        source: 'training',
      }),
    );

    let certificateTypesArray: RecordType[] = (
      await getCertificationTypes()
    ).map(t => ({
      id: t._id,
      name: t.name ?? '',
      source: 'certificate',
    }));

    let allTypes = trainingTypesArray
      .concat(certificateTypesArray)
      .sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : 0,
      );

    setFilteredStatus([...statuses]);
    setFilteredTypes(allTypes.map(t => t.id.toHexString()));
    setRecordsToShow(
      allTypes.map((t, i) => ({
        typeIndex: i,
        recordsToShow: DISPLAY_INCREMENT,
      })),
    );
    setExpandedItems(allTypes.map((t, i) => i));
    setRecordTypes(allTypes);

    if (allTypes.length > 0) {
      let submissions = (await getSubmissionTrainings())
        .filter(s => s.submissionStatus.name === SubmissionStatuses.SUBMITTED)
        .sort(
          (a, b) =>
            b.createDateTimeStamp.getTime() - a.createDateTimeStamp.getTime(),
        );

      let mappedRecords: Record[] = [];
      submissions.forEach(s => {
        let expiryDate: Date | undefined = undefined;
        if (s.metadataJSON) {
          let meta = JSON.parse(s.metadataJSON);

          let expiryDateMeta = meta[METADATA_KEYS.EXPIRY_DATE]
            ? meta[METADATA_KEYS.EXPIRY_DATE]
            : '';
          if (expiryDateMeta) {
            let momentDate = moment(expiryDateMeta, Formats.BACKEND_DATE);
            if (momentDate.isValid()) expiryDate = momentDate.toDate();
          }
        }

        let status = 'Valid';
        if (expiryDate) {
          if (expiryDate <= moment().startOf('day').toDate())
            status = 'Expired';
          else if (
            expiryDate <= moment().startOf('day').add(1, 'month').toDate()
          )
            status = 'Expiring Soon';
        } else status = 'Never Expires';

        let recordType: RecordType | undefined = undefined;
        if (s.trainingType.id && s.trainingType.name)
          recordType = {
            id: s.trainingType.id,
            name: s.trainingType.name ?? '',
            source: 'training',
          };
        else if (s.certificateType.id)
          recordType = {
            id: s.certificateType.id,
            name: s.certificateType.name ?? '',
            source: 'certificate',
          };

        if (recordType) {
          // Check if there are previous items for the same person and the same record type,
          let item = mappedRecords.find(
            r =>
              r.person.id?.equals(s.person.id!) &&
              r.type.id?.equals(recordType!.id!),
          );

          let addCurrent = false;
          if (item) {
            if (
              // If both has expiry date and current is greater than the other replace the item
              (item.expiryDate && expiryDate && expiryDate > item.expiryDate) ||
              // If current 'Never Expires' and the other has expiryDate replace the item
              // Otherwise, ignore the current item as they were previously sorted by creation date.
              (!expiryDate && item.expiryDate)
            ) {
              let itemIndex = mappedRecords.indexOf(item);
              mappedRecords.splice(itemIndex, 1);

              addCurrent = true;
            }
          } else addCurrent = true;

          if (addCurrent)
            mappedRecords.push({
              id: s._id,
              person: {
                id: s.person.id,
                name: s.person.firstName + ' ' + s.person.lastName,
              },
              status: status,
              expiryDate: expiryDate,
              type: recordType,
              icon: '',
              isLocked:
                s.submissionStatusLock == LOCK_STATUS.LOCK ||
                s.submissionStatusLock == LOCK_STATUS.SUPERLOCK,
            });
        }
      });

      mappedRecords = sortItems(mappedRecords);
      setRecords(mappedRecords);
    }
  };

  const goBack = () => {
    props.navigation.pop();
  };

  function getFilteredPersonId(): string {
    let filteredPersonId = '';

    try {
      let valObj = JSON.parse(filteredPerson) as {
        mongoId: string;
        SQLServerId: string;
      };

      filteredPersonId = valObj.mongoId;
    } catch (error) {}

    return filteredPersonId;
  }

  function expand(index: number): void {
    let newExpandedItems = [...expandedItems];

    newExpandedItems.push(index);

    setExpandedItems(newExpandedItems);
  }

  function collapse(index: number): void {
    let newExpandedItems = [...expandedItems];

    let arrayIndex = newExpandedItems.indexOf(index);
    if (arrayIndex !== -1) {
      newExpandedItems.splice(arrayIndex, 1);
      setExpandedItems(newExpandedItems);
    }
  }

  const incrementRecordsToShow = (index: number) => {
    let newRecordsToShow = [...recordsToShow];

    let recordsToShowByType = newRecordsToShow.filter(
      s => s.typeIndex === index,
    );
    if (recordsToShowByType.length > 0)
      recordsToShowByType[0].recordsToShow += DISPLAY_INCREMENT;

    setRecordsToShow(newRecordsToShow);
  };

  const goToRecord = (record: Record) => {
    props.navigation.push(Navigation.TRAININGFORM, {
      submissionId: record.id,
    });
  };

  const showPDFModal = (record: Record) => {
    setRecordToPDF(record);
    setIsShowingPDFModal(true);
  };

  function toggleFilteredStatus(status: Status): void {
    let newFilteredStatus = [...filteredStatus];

    let index = newFilteredStatus.indexOf(status);
    if (index !== -1) newFilteredStatus.splice(index, 1);
    else newFilteredStatus.push(status);

    setFilteredStatus(newFilteredStatus);
  }

  function toggleFilteredTypes(typeId: string): void {
    let newFilteredTypes = [...filteredTypes];

    let index = newFilteredTypes.indexOf(typeId);
    if (index !== -1) newFilteredTypes.splice(index, 1);
    else newFilteredTypes.push(typeId);

    setFilteredTypes(newFilteredTypes);
  }

  function sortItems(itemsToSort: Record[]): Record[] {
    return itemsToSort.sort((a, b) =>
      (a.person.name?.toLowerCase() ?? '') >
      (b.person.name?.toLowerCase() ?? '')
        ? 1
        : (a.person.name?.toLowerCase() ?? '') <
          (b.person.name?.toLowerCase() ?? '')
        ? -1
        : 0,
    );
  }

  const renderType = (type: RecordType, index: number): React.ReactElement => {
    // If type is not included in filters return empty element
    if (!filteredTypes.includes(type.id.toHexString()))
      return <React.Fragment key={index}></React.Fragment>;

    let filteredPersonId = getFilteredPersonId();
    let filteredRecords = records.filter(
      r =>
        r.type.id?.equals(type.id.toHexString()) &&
        // Search filter
        (search === '' ||
          r.person.name?.toLowerCase().includes(search.toLowerCase()) ||
          type.name?.toLowerCase().includes(search.toLowerCase())) &&
        // Person filter
        (filteredPersonId === '' || r.person.id?.equals(filteredPersonId)) &&
        // Status filter
        filteredStatus.includes(r.status),
    );

    let itemsToShow = DISPLAY_INCREMENT;
    let recordsToShowByType = recordsToShow.filter(s => s.typeIndex === index);
    if (recordsToShowByType.length > 0)
      itemsToShow = recordsToShowByType[0].recordsToShow;

    let visibleRecords = sortItems(filteredRecords).slice(0, itemsToShow);
    if (visibleRecords.length === 0)
      return <React.Fragment key={index}></React.Fragment>;

    return (
      <View key={index} style={{ paddingHorizontal: 20 }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 20,
          }}>
          <Text style={CommonStyleSheet.controlLabel}>{type.name}</Text>
          {!expandedItems.includes(index) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 28,
                  height: 28,
                  borderRadius: 28,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 12,
                },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              onPress={() => expand(index)}>
              <Icon icon={'expand'} color={Colors.darkestGreen} size={20} />
            </Pressable>
          )}
          {expandedItems.includes(index) && (
            <Pressable
              style={({ pressed }) => [
                {
                  width: 28,
                  height: 28,
                  borderRadius: 28,
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 12,
                },
                pressed && {
                  backgroundColor: Colors.darkGreenTransparent,
                },
              ]}
              onPress={() => collapse(index)}>
              <Icon icon={'collapse'} color={Colors.darkestGreen} size={20} />
            </Pressable>
          )}
        </View>
        {expandedItems.includes(index)
          ? visibleRecords.map((record, i) => {
              return renderRecord(record, i);
            })
          : null}
        {expandedItems.includes(index) &&
          filteredRecords.length > visibleRecords.length && (
            <Pressable
              style={({ pressed }) => [
                CommonStyleSheet.smallGreenButton,
                { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
                pressed && {
                  opacity: 0.8,
                },
              ]}
              onPress={() => incrementRecordsToShow(index)}>
              <Text style={CommonStyleSheet.smallGreenButtonText}>
                Show More
              </Text>
            </Pressable>
          )}
      </View>
    );
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          borderBottomWidth: 0.4,
          borderBottomColor: Colors.darkGreen,
        }}>
        <Pressable
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            {
              flex: 1,
              alignItems: 'center',
            },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => setSelectedRecord(record)}>
          {renderIcon(record)}
          <Text
            style={[
              ReportsStyleSheet.listItemName,
              {
                marginTop: 'auto',
                marginBottom: 'auto',
              },
            ]}
            numberOfLines={2}>
            {record.person.name}
          </Text>
          <View style={{ marginRight: 5 }}>
            {record.isLocked && (
              <Icon icon={'lock'} color={Colors.darkestGreen} size={16} />
            )}
          </View>
          {renderExpiryDate(record)}
          {selectedRecord?.id == record.id && renderActions()}
        </Pressable>
      </View>
    );
  };

  const renderIcon = (record: Record): React.ReactElement => {
    let text = '';
    let avatar = '';

    let match = record.person.name?.match(/\b(\w)/g);
    let acronym = match?.join('');
    if (acronym) text = acronym.substring(0, 2);
    if (record.icon) avatar = record.icon;

    return (
      <View style={[ControlsStyleSheet.facepile, { marginRight: 6 }]}>
        {avatar ? (
          <Image width={32} height={32} source={{ uri: avatar }} />
        ) : (
          <Text style={ControlsStyleSheet.facepileText}>{text}</Text>
        )}
      </View>
    );
  };

  const renderExpiryDate = (record: Record): React.ReactElement => {
    let date = record.expiryDate
      ? moment(record.expiryDate).format('MMM DD YYYY')
      : '';

    let backgroundColor = '';
    let color = '';

    switch (record.status) {
      case 'Valid':
      case 'Never Expires':
        backgroundColor = Colors.lightgray;
        color = Colors.darkGreen;
        break;
      case 'Expiring Soon':
        backgroundColor = Colors.yellow;
        color = Colors.darkGreen;
        break;
      case 'Expired':
        backgroundColor = Colors.red;
        color = Colors.white;
        break;
      default:
        break;
    }

    return (
      <View
        style={[
          ReportsStyleSheet.listItemDateContent,
          { backgroundColor: backgroundColor },
        ]}>
        <Text style={[ReportsStyleSheet.listItemDate, { color: color }]}>
          {date ? date : 'Never Expires'}
        </Text>
      </View>
    );
  };

  const renderActions = (): React.ReactElement => {
    const actions = (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <CustomPressable
          onPress={() => showPDFModal(selectedRecord!)}
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            ReportsStyleSheet.draftAction,
          ]}
          tooltip={'Generate PDF'}>
          <Icon icon={'print'} color={Colors.white} size={20} />
        </CustomPressable>
        <CustomPressable
          onPress={() => {
            goToRecord(selectedRecord!);
          }}
          style={({ pressed }) => [
            pressed && {
              opacity: 0.6,
            },
            ReportsStyleSheet.draftAction,
          ]}
          tooltip={'View'}>
          <Icon icon={'eye'} color={Colors.white} size={20} />
        </CustomPressable>
      </View>
    );

    return actions;
  };

  const renderPDFModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingPDFModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Download Documents
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setIsShowingPDFModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 24 }}>
              <View style={ControlsStyleSheet.attachmentsContainer}>
                {isShowingPDFModal && recordToPDF && (
                  <DynamicPDF
                    submissionId={recordToPDF.id.toHexString()}
                    type="submissionTraining"></DynamicPDF>
                )}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  const renderFilters = (): React.ReactElement => {
    if (!recordTypes) return <></>;

    return (
      <View
        style={{
          backgroundColor: Colors.lightgray,
          borderRadius: 12,
          marginVertical: 12,
          marginRight: 12,
          padding: 24,
        }}>
        <Text style={[CommonStyleSheet.controlLabel, { alignSelf: 'center' }]}>
          Filter
        </Text>
        <View>
          <Text style={CommonStyleSheet.controlLabel}>Person</Text>
          <DynamicAutocomplete
            config={{ optionSource: ListDataTypes.PEOPLE }}
            value={filteredPerson ? filteredPerson : ''}
            onChange={(controlId, controlTypeId, value) =>
              setFilteredPerson(value)
            }
          />
        </View>
        <View>
          <Text style={CommonStyleSheet.controlLabel}>Expiry Status</Text>
          {statuses.map((s, i) => {
            return (
              <View
                key={i}
                style={{
                  borderBottomWidth: 0.4,
                  borderBottomColor: Colors.darkGreen,
                  paddingVertical: 6,
                  marginHorizontal: 6,
                }}>
                <DynamicCheckBox
                  label={s}
                  value={filteredStatus.includes(s) ? 'true' : 'false'}
                  onChange={() => toggleFilteredStatus(s)}
                />
              </View>
            );
          })}
        </View>
        <View>
          <Text style={[CommonStyleSheet.controlLabel, { marginTop: 12 }]}>
            Type
          </Text>
          {recordTypes.map((t, i) => {
            return (
              <View
                key={i}
                style={{
                  borderBottomWidth: 0.4,
                  borderBottomColor: Colors.darkGreen,
                  paddingVertical: 6,
                  marginHorizontal: 6,
                }}>
                <DynamicCheckBox
                  label={t.name}
                  value={
                    filteredTypes.includes(t.id.toHexString())
                      ? 'true'
                      : 'false'
                  }
                  onChange={() => toggleFilteredTypes(t.id.toHexString())}
                />
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  return (
    <ScrollView>
      <View style={{ height: 260, backgroundColor: Colors.darkGreen }}>
        <View
          style={{
            flexDirection: 'row',
            paddingRight: 10,
            paddingTop: 10,
            justifyContent: 'flex-end',
          }}>
          <Pressable
            style={({ pressed }) => [
              pressed && {
                opacity: 0.4,
              },
            ]}
            onPress={() => goBack()}>
            <Icon icon={'close-circle'} color={Colors.white} size={24} />
          </Pressable>
        </View>
        <Text style={ReportsStyleSheet.headerTitle}>Training Breakdown</Text>
      </View>
      <View style={{ flexDirection: 'row-reverse' }}>
        <View style={{ flex: 1 }}>{renderFilters()}</View>
        <View style={{ flex: 2, padding: 28 }}>
          <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
          {recordTypes.map((type, i) => {
            return renderType(type, i);
          })}
        </View>
      </View>
      {renderPDFModal()}
    </ScrollView>
  );
};

export default TrainingBreakdown;
