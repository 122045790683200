export class TemplateCategoryTypes {
  public static readonly AUDIT = 'Audit';
  public static readonly QUICKADD = 'Quickadd';
  public static readonly HOME = 'Home';
  public static readonly REPORTS = 'Reports';
  public static readonly PEOPLE = 'People';
  public static readonly OPERATIONS = 'Operations';
  public static readonly EQUIPMENT = 'Equipment';
  public static readonly TASKS = 'Tasks';
  public static readonly INVESTIGATION = 'Investigation';
}

export declare type TemplateCategoryType =
  | typeof TemplateCategoryTypes.AUDIT
  | typeof TemplateCategoryTypes.QUICKADD
  | typeof TemplateCategoryTypes.HOME
  | typeof TemplateCategoryTypes.REPORTS
  | typeof TemplateCategoryTypes.PEOPLE
  | typeof TemplateCategoryTypes.OPERATIONS
  | typeof TemplateCategoryTypes.EQUIPMENT
  | typeof TemplateCategoryTypes.INVESTIGATION;

export class TemplateTypes {
  public static readonly INCIDENT = 'Incident';
  public static readonly TASK = 'Task';
  public static readonly TRAINING_BREAKDOWN = 'Training Breakdown';
  public static readonly AUDIT = 'Audit';
  public static readonly INVESTIGATION = 'Investigation';
}

export declare type TemplateType =
  | typeof TemplateTypes.INCIDENT
  | typeof TemplateTypes.TASK
  | typeof TemplateTypes.TRAINING_BREAKDOWN
  | typeof TemplateTypes.INVESTIGATION;
