import React, { useState } from 'react';
import { Pressable, PressableProps, Text } from 'react-native';
import Svg, { Polygon } from 'react-native-svg';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { View } from '@react-pdf/renderer';
import Colors from '../../Styles/Shared/Colors';

interface CustomPressable extends PressableProps {
  tooltip?: string;
  tooltipAlign?: 'left' | 'right';
  tooltipSize?: 'sm' | 'lg';
}

const CustomPressable = (props: CustomPressable): React.ReactElement => {
  const [show, setShow] = useState(false);

  return (
    <Pressable
      {...props}
      onHoverIn={() => setShow(true)}
      onHoverOut={() => setShow(false)}>
      <>
        {show && props.tooltip ? (
          <>
            <Text
              style={[
                CommonStyleSheet.toolTip,
                props.tooltipSize === 'lg'
                  ? { minWidth: 260 }
                  : { minWidth: 160 },
                props.tooltipAlign === 'left' ? { right: -8 } : { left: -8 },
              ]}
              numberOfLines={1}>
              {props.tooltip}
            </Text>
            <Svg
              width={24}
              height={12}
              style={{
                zIndex: 1000,
                position: 'absolute',
                display: 'flex',
                top: -16,
                left: 5,
              }}>
              <Polygon points="0,0 12,12 24,0" fill={Colors.green} />
            </Svg>
          </>
        ) : null}
        {props.children}
      </>
    </Pressable>
  );
};

export default CustomPressable;
