import React, { useState, useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  Image,
  ImageBackground,
  Text,
  Pressable,
} from 'react-native';
import * as Realm from 'realm-web';
import { useIsFocused } from '@react-navigation/native';

import RecordList from '../Shared/RecordList';
import QuickAddList from '../Shared/QuickAddList';
import Icon from '../Shared/Icon';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { useSync } from '../../Providers/SyncProvider';
import { TemplateCategoryTypes } from '../../Constants/TemplateCategoryTypes';
import { Submission } from '../../Models/RealmModels/Submission';
import FormList from '../Shared/FormList';
import {
  CATEGORY,
  METADATA_KEYS,
  PERMISSIONS,
} from '../../Constants/AppConstants';
import { usePermission } from '../../Providers/PermissionProvider';
import { useRelation } from '../../Providers/SubmissionRelationProvider';
import { FilterTypes } from '../../Constants/General';

export declare type ReportsProps = {
  navigation: any;
};

const Reports = (props: ReportsProps): React.ReactElement => {
  const [reports, setReports] = useState<Submission[]>();
  const {
    getPeople,
    getSubmissions,
    getFilteredTemplates,
    getRealmApp,
    submissions,
  } = useSync();
  const isFocused = useIsFocused();

  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
  const [noReportsFound, setNoReportsFound] = useState(false);
  const { hasPermission } = usePermission();
  const { getRelatedSubmissions } = useRelation();

  useEffect(() => {
    fetchSubmissions();
  }, [submissions]);

  useEffect(() => {
    const evaluatePermissions = async () => {};
    evaluatePermissions();
  }, []);

  useEffect(() => {
    if (isFocused) {
      fetchSubmissions();
    }
  }, [isFocused]);

  const unselectCommand = useRef({
    unselect: () => {},
    setUnselectCommand(func: () => void) {
      this.unselect = func;
    },
  });

  const fetchSubmissions = async () => {
    let templates = await getFilteredTemplates();
    let viewReport = hasPermission(PERMISSIONS.REPORT_VIEW);
    let filteredTemplates = templates
      .filter(
        t =>
          t.templateCategory.templateCategoryName ===
            TemplateCategoryTypes.QUICKADD ||
          t.templateCategory.templateCategoryName ===
            TemplateCategoryTypes.REPORTS,
      )
      .map(t => t.name);

    if (filteredTemplates.length === 0) setNoReportsFound(true);

    let submissions = await getSubmissions();
    let filteredSubmissions = submissions.filter(s =>
      filteredTemplates.includes(s.templateVersion.name!),
    );

    //If no view permissions can only see forms that user created
    if (!viewReport) {
      let user = getRealmApp().currentUser;
      if (user) {
        let userEmail = user.customData['email'];
        let personLoggedIn = (await getPeople({})).find(
          x => x.email == userEmail,
        );

        if (personLoggedIn) {
          let submissionIds = await getRelatedSubmissions(personLoggedIn._id);
          filteredSubmissions = filteredSubmissions.filter(
            x =>
              submissionIds.includes(x._id.toHexString()) ||
              x.createdBy == user?.customData['email'],
          );
        }
      }
    }

    setReports(filteredSubmissions);
  };

  return (
    <React.Fragment>
      <ScrollView style={{ backgroundColor: Colors.white }}>
        <Pressable
          style={{
            //@ts-ignore
            cursor: 'auto',
          }}
          onPress={() => {
            unselectCommand.current.unselect();
          }}>
          <>
            <View>
              <ImageBackground
                source={Images.REPORTS}
                resizeMode="cover"
                style={{ height: 260 }}>
                <Text style={ReportsStyleSheet.headerTitle}>Reports</Text>
              </ImageBackground>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 2, padding: 28 }}>
                {noReportsFound && supportEmail && (
                  <Text
                    style={[
                      ReportsStyleSheet.listSubtitle,
                      { textTransform: 'none', letterSpacing: 0 },
                    ]}>
                    {'No reports found. Please contact Support at ' +
                      supportEmail +
                      ' to get reports added to your account.'}
                  </Text>
                )}
                <RecordList
                  records={reports}
                  showDrafts={true}
                  hasMetaData={true}
                  category={CATEGORY.REPORTS}
                  metaDataKey_title={METADATA_KEYS.TITLE}
                  metaDataKey_project={METADATA_KEYS.PROJECT_NAME}
                  showFilters={true}
                  showInvestigation={true}
                  filters={[
                    { label: 'Date Range', type: FilterTypes.DATE_RANGE },
                    { label: 'Type', type: FilterTypes.REPORT_TYPE },
                    { label: '', type: FilterTypes.INVESTIGATION },
                  ]}
                  navigation={props.navigation}
                  setUnselectCommand={(func: () => void) => {
                    unselectCommand.current.setUnselectCommand(func);
                  }}
                />
              </View>
              <View style={{ flex: 1 }}>
                <View style={ReportsStyleSheet.fabContainer}>
                  <FormList
                    templateCategoryName={TemplateCategoryTypes.REPORTS}
                    navigation={props.navigation}
                    category={CATEGORY.REPORTS}
                  />
                </View>
                <View style={CommonStyleSheet.bcsfFooter}>
                  <Image
                    source={Images.BCSF}
                    style={CommonStyleSheet.bcsfFooterImage}
                  />
                  <Text style={CommonStyleSheet.bcsfFooterText}>
                    BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
                  </Text>
                </View>
              </View>
            </View>
          </>
        </Pressable>
      </ScrollView>
      <QuickAddList navigation={props.navigation} />
    </React.Fragment>
  );
};

export default Reports;
