export enum ControlTypes {
  LABEL = 1,
  DATE,
  TIME,
  MAP,
  TEXTBOX,
  TEXTAREA,
  SELECT,
  RATING,
  TAG_ITEM,
  PHOTOS_AND_ATTACHMENTS,
  SUBMIT,
  GROUP_SELECT,
  SEGMENTED_CONTROL,
  SIGNATURE,
  LINK_RECORD,
  REPORT_DESCRIPTION,
  CHECK_BOX,
  FACEPILE,
  LINKED_DROPDOWNS,
  TIP,
  AUTOCOMPLETE,
  RISK_RATING,
  SIGNATURE_LIST,
  RADIO,
  CHECKBOXGROUP,
  AUTOCOMPLETE_EXTENDED,
  PEOPLE_LIST,
}
