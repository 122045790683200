import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { View, Page, Text, Image } from '@react-pdf/renderer';
import IConditionsHelperService from '../../Services/Interfaces/IConditionsHelperService';
import ConditionsHelperService from '../../Services/ConditionsHelperService';
import {
  TemplateVersion,
  TemplateVersion_pages,
  TemplateVersion_pages_controls as TemplateControl,
} from '../../Models/RealmModels/TemplateVersion';
import { Submission } from '../../Models/RealmModels/Submission';
import { MongoIdSqlId, SubmissionAnswerDTO } from '../../Types/DtoTypes';
import { ControlTypes } from '../../Constants/ControlTypes';
import {
  AutocompleteExtendedConfig,
  CheckboxGroupConfig,
  LabelConfig,
  LinkRecordConfig,
  PeopleListConfig,
  RadioConfig,
  RatingConfig,
  SelectItemConfig,
} from '../../Types/ControlConfigTypes';
import { DynamicAttachment } from '../../Types/ControlTypes';
import { SignatureListItem } from '../DynamicControls/DynamicSignatureList';
import { Formats } from '../../Constants/Formats';
import { METADATA_KEYS } from '../../Constants/AppConstants';
import { PDFStyles } from './DynamicPDF';
import { render } from '@testing-library/react';
import { Project } from '../../Models/RealmModels/Projects';
import { Person } from '../../Models/RealmModels/Person';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { useSync } from '../../Providers/SyncProvider';
import { City } from '../../Models/RealmModels/City';
import { Job } from '../../Models/RealmModels/Job';
import { ProvState } from '../../Models/RealmModels/ProvState';
import { PeopleListItem } from '../DynamicControls/DynamicPeopleList';

type LinkedReport = {
  title: string;
  date: string;
};

type DynamicPDFSubmissionProps = {
  orgName: string;
  title: string;
  date: string;
  template: TemplateVersion;
  submission: Submission;
  linkedReports?: LinkedReport[];
  listData: {
    projects: Project[];
    people: Person[];
    cities: City[];
    getJobs: () => Job[];
    getProvStates: () => ProvState[];
  };
};

const DynamicPDFSubmission = (
  props: DynamicPDFSubmissionProps,
): React.ReactElement => {
  const condHelper: IConditionsHelperService = new ConditionsHelperService();
  const [controls, setControls] = useState<Map<number, TemplateControl>>(
    new Map<number, TemplateControl>(),
  );
  const [answers, setAnswers] = useState<SubmissionAnswerDTO[]>([]);

  useEffect(() => {
    // Get answers
    let submissionAnswers: SubmissionAnswerDTO[] = props.submission.answers.map(
      x => ({
        controlId: x.controlId,
        controlTypeId: x.controlTypeId,
        answer: x.answer,
      }),
    );
    setAnswers(submissionAnswers);

    // Get controls
    let submissionControls: Map<number, TemplateControl> = new Map<
      number,
      TemplateControl
    >();
    for (let i = 0; i < props.template.pages.length; i++) {
      let page = props.template.pages[i];
      if (page.controls)
        for (let j = 0; j < page.controls.length; j++) {
          submissionControls.set(page.controls[j].controlId!, page.controls[j]);
        }
    }
    setControls(submissionControls);
  }, []);

  const renderCover = (): React.ReactElement => {
    return (
      <Page size="A4">
        <View
          style={{
            alignItems: 'center',
            paddingHorizontal: 120,
            marginTop: 200,
          }}>
          <Text style={PDFStyles.title}>{props.orgName}</Text>
          <Text style={[PDFStyles.label, { textAlign: 'center' }]}>
            {props.title}
          </Text>
          <Text style={PDFStyles.value}>{props.date}</Text>
        </View>
      </Page>
    );
  };

  const renderDocument = (): React.ReactElement => {
    return (
      <Page size="A4">
        {props.template &&
          props.template.pages.map((page, i) => {
            return renderPage(page, i);
          })}
      </Page>
    );
  };

  const renderPage = (
    page: TemplateVersion_pages,
    index: number,
  ): React.ReactElement => {
    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingHorizontal: 24,
          paddingTop: 24,
        }}>
        {page &&
          page.controls &&
          page.controls.map((control, i) => {
            return renderControl(control, i);
          })}
      </View>
    );
  };

  const renderControl = (
    control: TemplateControl,
    index: number,
  ): React.ReactElement => {
    const submissionAnswer = answers.find(
      x => x.controlId == control.controlId,
    );

    let answer = control.value;
    if (submissionAnswer && submissionAnswer.answer)
      answer = submissionAnswer.answer;

    let visible = condHelper.isControlVisible(
      control,
      props.template.conditions ?? [],
      answers,
      controls,
    );
    if (!visible)
      return <React.Fragment key={control.controlId}></React.Fragment>;

    switch (control.controlTypeId) {
      case ControlTypes.LABEL:
        let labelConfig: LabelConfig = {};

        try {
          if (control.config)
            labelConfig = JSON.parse(control.config) as LabelConfig;
        } catch (err) {
          console.log('Error: Bad control config serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text
              style={{
                fontFamily: 'Poppins',
                fontSize: labelConfig.fontSize ?? 12,
                fontWeight:
                  labelConfig.fontWeight &&
                  (labelConfig.fontWeight == 'bold' ||
                    labelConfig.fontWeight == '700')
                    ? 'bold'
                    : 'normal',
              }}>
              {control.label}
            </Text>
          </View>
        );
      case ControlTypes.DATE:
        let date = null;
        let momentDate = moment(answer, Formats.BACKEND_DATE);
        if (momentDate.isValid()) date = momentDate.startOf('day').toDate();

        return (
          <View
            key={index}
            style={{ flexBasis: '33%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={PDFStyles.value}>
              {date ? moment(date).format(Formats.FRONTEND_DATE) : ''}
            </Text>
          </View>
        );
      case ControlTypes.TIME:
        let time = null;
        let momentTime = moment(answer, Formats.BACKEND_TIME);
        if (momentTime.isValid()) time = momentTime.toDate();

        return (
          <View
            key={index}
            style={{ flexBasis: '33%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={PDFStyles.value}>
              {time ? moment(time).format(Formats.FRONTEND_TIME) : ''}
            </Text>
          </View>
        );
      case ControlTypes.TEXTBOX:
      case ControlTypes.TEXTAREA:
      case ControlTypes.REPORT_DESCRIPTION:
        return (
          <View
            key={index}
            style={{ flexBasis: '33%', paddingVertical: 6, paddingRight: 6 }}
            /* Texts longer than one page need "wrap = true" */
            wrap={answer && answer.length > 1000 ? true : false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={PDFStyles.value}>
              {answer ? answer : 'Not Specified'}
            </Text>
          </View>
        );
      case ControlTypes.AUTOCOMPLETE:
      case ControlTypes.SELECT:
        const selectItemConfig: SelectItemConfig = JSON.parse(control.config!);

        let valueObj: MongoIdSqlId | null = null;
        if (answer) {
          if (selectItemConfig.options && selectItemConfig.options.length > 0) {
            //if control has local options configured just take answer value
            valueObj = { label: answer };
          } else {
            try {
              valueObj = JSON.parse(answer) as MongoIdSqlId;
            } catch (error) {
              console.log('Error: Bad control value serialization');
            }
          }
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '33%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={{ fontFamily: 'Poppins', fontSize: 14 }}>
              {valueObj ? valueObj.label : 'Not Specified'}
            </Text>
          </View>
        );
      case ControlTypes.AUTOCOMPLETE_EXTENDED:
        return renderAutoCompleteExtended(index, control, answer);
      case ControlTypes.CHECK_BOX:
        return (
          <View
            key={index}
            style={{
              flexBasis: '100%',
              flexDirection: 'row',
              gap: 12,
              paddingVertical: 6,
              paddingRight: 32,
            }}
            wrap={false}>
            <Image
              source={
                answer === 'true'
                  ? '/img/checkbox-checked.png'
                  : '/img/checkbox-unchecked.png'
              }
              style={{ width: 18, height: 18 }}
            />
            <Text style={PDFStyles.value}>{control.label}</Text>
          </View>
        );
      case ControlTypes.SEGMENTED_CONTROL:
        return (
          <View
            key={index}
            style={{ flexBasis: '33%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={PDFStyles.value}>
              {answer ? answer : 'Not Specified'}
            </Text>
          </View>
        );
      case ControlTypes.GROUP_SELECT:
        let options: string[] = [];

        if (answer) {
          try {
            options = JSON.parse(answer) as [];
          } catch (error) {
            console.log('Error: Bad control value serialization');
          }
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={PDFStyles.value}>
              {options.map((option, index) => {
                return index === 0 ? option : ', ' + option;
              })}
            </Text>
          </View>
        );
      case ControlTypes.TAG_ITEM:
      case ControlTypes.FACEPILE:
        let valueObjs: MongoIdSqlId[] = [];

        if (answer) {
          try {
            let valuesArray = JSON.parse(answer) as [];
            valuesArray.forEach(val => {
              valueObjs.push(JSON.parse(val) as MongoIdSqlId);
            });
          } catch (error) {
            console.log('Error: Bad control value serialization');
          }
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={PDFStyles.value}>
              {valueObjs.map((obj, index) => {
                return index === 0 ? obj.label : ', ' + obj.label;
              })}
            </Text>
          </View>
        );
      case ControlTypes.RATING:
        let ratingValue = 'Not Specified';
        try {
          if (answer && control.config) {
            let ratingConfig = JSON.parse(control.config) as RatingConfig;

            let selectedLevel = ratingConfig.ratingLevels.find(
              l => l.value.toString() == answer,
            );

            if (selectedLevel) ratingValue = selectedLevel.name;
          }
        } catch (err) {
          console.log('Error: Bad control config serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            <Text style={PDFStyles.value}>{ratingValue}</Text>
          </View>
        );
      case ControlTypes.RISK_RATING:
        let actualValue = 'Not Specified';
        let potentialValue = 'Not Specified';
        let probabilityValue = 'Not Specified';
        try {
          if (answer) {
            let answerObj = JSON.parse(answer);

            if (answerObj.actual)
              actualValue =
                (JSON.parse(answerObj.actual) as MongoIdSqlId).label ?? '';

            if (answerObj.potential)
              potentialValue =
                (JSON.parse(answerObj.potential) as MongoIdSqlId).label ?? '';

            if (answerObj.probability)
              probabilityValue =
                (JSON.parse(answerObj.probability) as MongoIdSqlId).label ?? '';
          }
        } catch (err) {
          console.log('Error: Bad control config serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>Actual Severity Index</Text>
            <Text style={PDFStyles.value}>{actualValue}</Text>
            <Text style={PDFStyles.label}>Potential Severity Index</Text>
            <Text style={PDFStyles.value}>{potentialValue}</Text>
            <Text style={PDFStyles.label}>Probability Index Of Occurrence</Text>
            <Text style={PDFStyles.value}>{probabilityValue}</Text>
          </View>
        );
      case ControlTypes.SIGNATURE:
        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            {answer && (
              <View style={{ maxWidth: '50%', maxHeight: 100 }}>
                <Image style={{ objectFit: 'contain' }} source={answer} />
              </View>
            )}
          </View>
        );
      case ControlTypes.SIGNATURE_LIST:
        let signatureList: SignatureListItem[] = [];
        try {
          if (answer) signatureList = JSON.parse(answer) as SignatureListItem[];
        } catch (err) {
          console.log('Error: Bad control serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            {signatureList.map((item, i) => {
              return (
                <View key={i} style={{ maxWidth: '50%', maxHeight: 100 }}>
                  <Text style={PDFStyles.value}>{item.personName}</Text>
                  {item.signature && (
                    <Image
                      style={{ objectFit: 'contain' }}
                      source={item.signature}
                    />
                  )}
                </View>
              );
            })}
          </View>
        );
      case ControlTypes.PHOTOS_AND_ATTACHMENTS:
        let attachmentsList: DynamicAttachment[] = [];
        try {
          if (answer)
            attachmentsList = JSON.parse(answer) as DynamicAttachment[];
        } catch (err) {
          console.log('Error: Bad control value serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6 }}
            wrap={false}>
            {attachmentsList.map((item, i) => {
              return (
                <Text key={i} style={PDFStyles.value}>
                  {item.name}
                </Text>
              );
            })}
            {attachmentsList.length > 0 && (
              <>
                <Text style={[PDFStyles.value, { fontSize: 10 }]}>
                  *Attached images will be added to the end of this document.
                </Text>
                <Text style={[PDFStyles.value, { fontSize: 10 }]}>
                  *Attached PDFs must be downloaded separately.
                </Text>
              </>
            )}
          </View>
        );
      case ControlTypes.MAP:
        let location: { latitude: number; longitude: number } | null = null;
        try {
          if (answer)
            location = JSON.parse(answer) as {
              latitude: number;
              longitude: number;
            };
        } catch (err) {
          console.log('Error: Bad control value serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>Location</Text>
            {location ? (
              <>
                <Text style={PDFStyles.value}>
                  Latitude: {location.latitude.toString()}
                </Text>
                <Text style={PDFStyles.value}>
                  Longitude: {location.longitude.toString()}
                </Text>
              </>
            ) : (
              <Text style={PDFStyles.value}>Not Specified</Text>
            )}
          </View>
        );
      case ControlTypes.LINK_RECORD:
        let linkRecordConfig: LinkRecordConfig = JSON.parse(control.config!);
        try {
          if (control.config) {
            linkRecordConfig = JSON.parse(control.config);
            if (linkRecordConfig.disabled === true)
              return <React.Fragment key={control.controlId}></React.Fragment>;
          }
        } catch (err) {
          console.log('Error: Bad control config serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6 }}
            wrap={false}>
            {props.linkedReports &&
              props.linkedReports.map((r, i) => {
                return (
                  <Text key={i} style={PDFStyles.value}>
                    {r.title} - {r.date}
                  </Text>
                );
              })}
            <Text style={[PDFStyles.value, { fontSize: 10 }]}>
              *Linked reports will be added to the end of the document.
            </Text>
            <Text style={[PDFStyles.value, { fontSize: 10 }]}>{answer}</Text>
          </View>
        );
      case ControlTypes.RADIO:
        let radioConfig: RadioConfig | null = null;
        try {
          if (control.config) {
            radioConfig = JSON.parse(control.config);
          }
        } catch (err) {
          console.log('Error: Bad radio control config serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6 }}
            wrap={false}>
            <Text style={PDFStyles.value}>{control.label}</Text>
            {radioConfig &&
              radioConfig.radios &&
              radioConfig.radios.map((r, i) => {
                return (
                  <View
                    key={r.value}
                    style={{ display: 'flex', flexDirection: 'row' }}>
                    <Image
                      source={
                        answer == r.value
                          ? '/img/checkbox-checked.png'
                          : '/img/checkbox-unchecked.png'
                      }
                      style={{ width: 18, height: 18 }}
                    />
                    <Text style={PDFStyles.value}>{r.label}</Text>
                  </View>
                );
              })}
          </View>
        );
      case ControlTypes.CHECKBOXGROUP:
        let checkboxGroupConfig: CheckboxGroupConfig | null = null;
        try {
          if (control.config) {
            checkboxGroupConfig = JSON.parse(control.config);
          }
        } catch (err) {
          console.log('Error: Bad radio control config serialization');
        }
        let checkboxGroupAnswer: string[] = [];
        if (checkboxGroupConfig == null) return <></>;

        if (answer) checkboxGroupAnswer = JSON.parse(answer);

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6 }}
            wrap={false}>
            {checkboxGroupConfig &&
              checkboxGroupConfig.items &&
              checkboxGroupConfig.items.map((r, i) => {
                return (
                  <View
                    key={r.value}
                    style={{ display: 'flex', flexDirection: 'row' }}>
                    <Image
                      source={
                        checkboxGroupAnswer.includes(r.value!)
                          ? '/img/checkbox-checked.png'
                          : '/img/checkbox-unchecked.png'
                      }
                      style={{ width: 18, height: 18 }}
                    />
                    <Text style={PDFStyles.value}>{r.label}</Text>
                  </View>
                );
              })}
          </View>
        );
      case ControlTypes.PEOPLE_LIST:
        let peopleList: PeopleListItem[] = [];

        try {
          if (answer) peopleList = JSON.parse(answer) as PeopleListItem[];
        } catch (err) {
          console.log('Error: Bad control serialization');
        }

        return (
          <View
            key={index}
            style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
            wrap={false}>
            <Text style={PDFStyles.label}>{control.label}</Text>
            {peopleList.map((item, i) => {
              return (
                <View key={i} style={{ marginVertical: 6 }}>
                  <Text style={PDFStyles.value}>{item.personName}</Text>
                  <Text style={PDFStyles.value}>{item.description}</Text>
                </View>
              );
            })}
          </View>
        );
      default:
        return <React.Fragment key={control.controlId}></React.Fragment>;
    }
  };

  const renderAutoCompleteExtendedTextFields = (
    autocompleteExtendedConfig: AutocompleteExtendedConfig,
    value: string | undefined,
    control: TemplateControl,
  ) => {
    let textInfo = [];
    type ProjectField = keyof Project;
    type PersonField = keyof Person;

    function isValidProjectField(
      value: string,
    ): asserts value is ProjectField {}

    function isValidPeopleField(value: string): asserts value is PersonField {}

    if (!value) return <></>;

    let valObj = JSON.parse(value) as MongoIdSqlId;

    if (autocompleteExtendedConfig.optionSource === ListDataTypes.PROJECTS) {
      let project = props.listData.projects.find(
        x => x._id.toHexString() === valObj.mongoId,
      );
      if (autocompleteExtendedConfig.dataFields?.length && project) {
        for (
          let i = 0;
          i < autocompleteExtendedConfig.dataFields?.length;
          i++
        ) {
          let fieldName = autocompleteExtendedConfig.dataFields[i];
          isValidProjectField(fieldName.fieldName);
          let fieldValue = project![fieldName.fieldName];
          if (fieldName.fieldName === 'provStateSQLServerId') {
            let provStateName = '';

            if (fieldValue) {
              let selectedProvState = props.listData
                .getProvStates()
                .find(x => x.SQLServerId == fieldValue);
              if (selectedProvState)
                provStateName = selectedProvState.provState!;
            }

            textInfo.push(renderLabelText(fieldName.label, provStateName));
          } else if (fieldName.fieldName === 'citySQLServerId') {
            let cityName = '';

            if (fieldValue) {
              let selectedCity = props.listData.cities.find(
                x => x.SQLServerId == fieldValue,
              );
              if (selectedCity) cityName = selectedCity.cityName!;

              textInfo.push(renderLabelText(fieldName.label, cityName));
            }
          } else if (
            fieldName.fieldName === 'cityName' &&
            typeof fieldValue === 'string'
          ) {
            if (!project.citySQLServerId)
              textInfo.push(renderLabelText(fieldName.label, fieldValue));
          } else if (typeof fieldValue === 'string') {
            textInfo.push(renderLabelText(fieldName.label, fieldValue));
          } else {
            textInfo.push(renderLabelText(fieldName.label, ''));
          }
        }
      }
    } else if (
      autocompleteExtendedConfig.optionSource === ListDataTypes.PEOPLE &&
      value
    ) {
      let person = props.listData.people.find(
        x => x._id.toHexString() === valObj.mongoId?.toString(),
      );

      if (autocompleteExtendedConfig.dataFields?.length && person) {
        for (
          let i = 0;
          i < autocompleteExtendedConfig.dataFields?.length;
          i++
        ) {
          let fieldName = autocompleteExtendedConfig.dataFields[i];
          isValidPeopleField(fieldName.fieldName);
          let fieldValue = person![fieldName.fieldName];
          if (fieldName.fieldName === 'jobSQLServerId') {
            let jobName = '';

            if (fieldValue) {
              let selectedJob = props.listData
                .getJobs()
                .find(x => x.SQLServerId == fieldValue);
              if (selectedJob) jobName = selectedJob.name!;
            }
            textInfo.push(renderLabelText(fieldName.label, jobName));
          } else if (typeof fieldValue === 'string')
            textInfo.push(renderLabelText(fieldName.label, fieldValue));
          else textInfo.push(renderLabelText(fieldName.label, ''));
        }
      }
    }
    return textInfo;
  };

  const renderLabelText = (
    label: string | undefined,
    value: string | undefined,
  ) => {
    return (
      <View style={{ flexBasis: '33%' }}>
        <Text style={PDFStyles.label}>{label ?? ''}</Text>
        <Text style={{ fontFamily: 'Poppins', fontSize: 14 }}>
          {value ? value : 'Not Specified'}
        </Text>
      </View>
    );
  };

  const renderAutoCompleteExtended = (
    index: number,
    control: TemplateControl,
    answer: string | undefined,
  ) => {
    let valueObj: MongoIdSqlId | null = null;
    if (answer) {
      try {
        valueObj = JSON.parse(answer) as MongoIdSqlId;
      } catch (error) {
        console.log('Error: Bad control value serialization');
      }
    }

    let autocompleteExtendedConfig: AutocompleteExtendedConfig = {};

    try {
      autocompleteExtendedConfig = JSON.parse(control.config!);
    } catch (err) {
      console.error(
        'Invalid config for autocomplete ' + control.controlId?.toString(),
      );
    }

    return (
      <View
        key={index}
        style={{ flexBasis: '100%', paddingVertical: 6, paddingRight: 6 }}
        wrap={false}>
        <Text style={PDFStyles.subTitle}>{control.label ?? ''}</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {renderLabelText(
            autocompleteExtendedConfig.autocompleteLabel,
            valueObj?.label,
          )}
          {renderAutoCompleteExtendedTextFields(
            autocompleteExtendedConfig,
            answer,
            control,
          )}
        </View>
      </View>
    );
    return <></>;
  };

  return (
    <>
      {renderCover()}
      {renderDocument()}
    </>
  );
};

export default DynamicPDFSubmission;
