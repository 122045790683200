import React, { useState, useEffect } from 'react';
import { ScrollView, View, Image, Text, Pressable, Modal } from 'react-native';
import { ObjectId, UUID } from 'bson';
import { GenericListItem, useSync } from '../../Providers/SyncProvider';
import { useIsFocused } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import SearchBox from '../Shared/SearchBox';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import DynamicSelect from '../DynamicControls/DynamicSelect';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import Icon from '../Shared/Icon';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { Images } from '../../Constants/Images';
import Colors from '../../Styles/Shared/Colors';
import { RegexValidation } from '../../Constants/General';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { PERMISSIONS, STORAGE_KEYS } from '../../Constants/AppConstants';
import { Icons } from '../../Constants/Icons';
import CustomPressable from '../Shared/CustomPressable';
import { StorageKeyType } from '../../Types/StorageTypes';
import DynamicCheckBox from '../DynamicControls/DynamicCheckBox';
import { Task } from '../../Models/RealmModels/Task';
import { TaskType } from '../../Models/RealmModels/TaskType';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import { usePermission } from '../../Providers/PermissionProvider';
import { IncidentClassification } from '../../Models/RealmModels/IncidentClassification';
import { IncidentType } from '../../Models/RealmModels/IncidentType';
import { SeverityLevel } from '../../Models/RealmModels/SeverityLevel';
import { IncidentProbability } from '../../Models/RealmModels/IncidentProbability';

export declare type EditListManagementProps = {
  navigation: any;
  listName: string;
  listKey: string;
  listLabel: string;
  listType: string;
};

const EditListManagement = (
  props: EditListManagementProps,
): React.ReactElement => {
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState<GenericListItem[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<GenericListItem | null>(
    null,
  );
  const [showAll, setShowAll] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showModalErrors, setShowModalErrors] = useState(false);
  const [modalRecord, setModalRecord] = useState<GenericListItem | null>(null);
  const [addingRecord, setAddingRecord] = useState(false);

  const [sortOrderValue, setSortOrderValue] = useState('');
  const [masterSource, setMasterSource] = useState('');
  const [masterLabel, setMasterLabel] = useState('');
  const [masterValue, setMasterValue] = useState('');
  const [itemValue, setItemValue] = useState('');
  const [nameError, setNameError] = useState('');

  const {
    getListData,
    upsertListData,
    upsertTask,
    upsertIncidentClassification,
    upsertIncidentProbability,
    upsertSeverityLevel,
    getOrgId,
  } = useSync();
  const { hasPermission } = usePermission();
  const [canUpsert, setCanUpsert] = useState(false);

  const isFocused = useIsFocused();
  const toast = useToast();

  useEffect(() => {
    let permission = hasPermission(PERMISSIONS.LISTMANAGEMENT_UPSERT);
    setCanUpsert(permission);
  }, []);

  useEffect(() => {
    if (isFocused) fetchRecords();
  }, [isFocused]);

  const fetchRecords = async () => {
    let orgId = getOrgId();

    if (props.listName == ListDataTypes.TASKS) {
      let tasksArray = getListData<Task>(props.listKey as StorageKeyType);

      let mappedTasks: GenericListItem[] = tasksArray.map(x => ({
        _id: x._id,
        name: x.name,
        masterValue: x.taskTypeSQLServerId,
        masterLabel: getMasterLabel(x.taskTypeSQLServerId),
        sortOrder: x.sortOrder ?? 0,
        partition: x.partition ?? orgId,
        isActive: x.isActive ?? true,
        isDeleted: x.isDeleted ?? false,
        createDateTimeStamp: x.createDateTimeStamp ?? new Date(),
        SQLServerId: x.SQLServerId ?? '',
      }));

      mappedTasks = sortRecords(mappedTasks);
      setRecords(mappedTasks);
    } else if (props.listName == ListDataTypes.INCIDENTCLASSIFICATIONS) {
      let incidentClassificationArray = getListData<IncidentClassification>(
        props.listKey as StorageKeyType,
      );

      let mappedTasks: GenericListItem[] = incidentClassificationArray.map(
        x => ({
          _id: x._id,
          name: x.name,
          masterValue: x.incidentTypeSQLServerId,
          masterLabel: getMasterLabel(x.incidentTypeSQLServerId ?? ''),
          sortOrder: x.sortOrder ?? 0,
          partition: x.partition ?? orgId,
          isActive: x.isActive ?? true,
          isDeleted: x.isDeleted ?? false,
          createDateTimeStamp: x.createDateTimeStamp ?? new Date(),
          SQLServerId: x.SQLServerId ?? '',
        }),
      );

      mappedTasks = sortRecords(mappedTasks);
      setRecords(mappedTasks);
    } else {
      let recordsArray = getListData<GenericListItem>(
        props.listKey as StorageKeyType,
      );

      let mappedRecords = recordsArray.map(x => ({
        _id: x._id,
        name: x.name,
        sortOrder: x.sortOrder ?? 0,
        partition: x.partition ?? orgId,
        isActive: x.isActive ?? true,
        isDeleted: x.isDeleted ?? false,
        createDateTimeStamp: x.createDateTimeStamp ?? new Date(),
        SQLServerId: x.SQLServerId ?? '',
      }));

      mappedRecords = sortRecords(mappedRecords);
      setRecords(mappedRecords);
    }

    if (props.listType == 'master-detail') {
      if (props.listName == ListDataTypes.TASKS) {
        setMasterSource(ListDataTypes.TASKTYPES);
        setMasterLabel('Task Type');
      }
      if (props.listName == ListDataTypes.INCIDENTCLASSIFICATIONS) {
        setMasterSource(ListDataTypes.INCIDENTTYPES);
        setMasterLabel('Incident Type');
      }
    }
  };

  function getMasterLabel(masterValue: string): string {
    if (props.listName == ListDataTypes.TASKS) {
      let taskTypeObj = getListData<TaskType>(
        STORAGE_KEYS.REALM_TASKTYPES,
      ).find(x => x.SQLServerId.toLowerCase() == masterValue?.toLowerCase());

      if (taskTypeObj) return taskTypeObj.name;
    } else if (props.listName == ListDataTypes.INCIDENTCLASSIFICATIONS) {
      let incidentTypeObj = getListData<IncidentType>(
        STORAGE_KEYS.REALM_INCIDENTTYPES,
      ).find(x => x.SQLServerId.toLowerCase() == masterValue?.toLowerCase());

      if (incidentTypeObj) return incidentTypeObj.name;
    }

    return '';
  }

  function sortRecords(itemsToSort: GenericListItem[]): GenericListItem[] {
    return itemsToSort.sort(
      (a, b) =>
        a.sortOrder - b.sortOrder ||
        (a.name > b.name ? 1 : a.name < b.name ? -1 : 0),
    );
  }

  function goBack() {
    props.navigation.pop();
  }

  function addRecord(): void {
    let orgId = getOrgId();

    let newRecord: GenericListItem = {
      _id: new ObjectId(),
      name: '',
      sortOrder: 0,
      partition: orgId,
      isActive: true,
      isDeleted: false,
      createDateTimeStamp: new Date(),
      SQLServerId: new UUID().toHexString().toUpperCase(),
    };

    setMasterValue('');
    setItemValue('');

    setAddingRecord(true);
    setModalRecord(newRecord);
    setSortOrderValue('');
    setShowModalErrors(false);
    setShowModal(true);
  }

  function viewRecord(): void {
    if (props.listType == 'master-detail') {
      if (props.listName == ListDataTypes.TASKS) {
        let taskObj = getListData<Task>(props.listKey as StorageKeyType).find(
          x => x._id.equals(selectedRecord?._id ?? ''),
        );

        if (taskObj && taskObj.taskTypeSQLServerId) {
          let taskTypeObj = getListData<TaskType>(
            STORAGE_KEYS.REALM_TASKTYPES,
          ).find(
            x =>
              x.SQLServerId.toLowerCase() ==
              taskObj!.taskTypeSQLServerId.toLowerCase(),
          );

          if (taskTypeObj) {
            let mongoIdSqlId: MongoIdSqlId = {
              mongoId: taskTypeObj._id.toHexString(),
              label: taskTypeObj.name,
              SQLServerId: taskTypeObj.SQLServerId,
            };

            setMasterValue(JSON.stringify(mongoIdSqlId));
          }
        }
      } else if (props.listName == ListDataTypes.INCIDENTCLASSIFICATIONS) {
        let incidentClassificationsObj = getListData<IncidentClassification>(
          props.listKey as StorageKeyType,
        ).find(x => x._id.equals(selectedRecord?._id ?? ''));

        if (
          incidentClassificationsObj &&
          incidentClassificationsObj.incidentTypeSQLServerId
        ) {
          let incidentTypeObj = getListData<IncidentType>(
            STORAGE_KEYS.REALM_INCIDENTTYPES,
          ).find(
            x =>
              x.SQLServerId.toLowerCase() ==
              incidentClassificationsObj!.incidentTypeSQLServerId?.toLowerCase(),
          );

          if (incidentTypeObj) {
            let mongoIdSqlId: MongoIdSqlId = {
              mongoId: incidentTypeObj._id.toHexString(),
              label: incidentTypeObj.name,
              SQLServerId: incidentTypeObj.SQLServerId,
            };

            setMasterValue(JSON.stringify(mongoIdSqlId));
          }
        }
      }
    } else {
      setMasterValue('');
    }

    if (props.listType == 'name-value') {
      if (props.listName == ListDataTypes.SEVERITYLEVELS) {
        let severityLevelObj = getListData<SeverityLevel>(
          props.listKey as StorageKeyType,
        ).find(r => r._id.equals(selectedRecord?._id ?? ''));
        setItemValue(severityLevelObj?.value ?? '');
      } else if (props.listName == ListDataTypes.INCIDENTPROBABILITIES) {
        let incidentProbabilityObj = getListData<IncidentProbability>(
          props.listKey as StorageKeyType,
        ).find(r => r._id.equals(selectedRecord?._id ?? ''));
        setItemValue(incidentProbabilityObj?.value ?? '');
      }
    } else {
      setItemValue('');
    }

    setAddingRecord(false);
    setModalRecord(selectedRecord);
    setSortOrderValue(selectedRecord?.sortOrder.toString() ?? '');
    setShowModalErrors(false);
    setShowModal(true);
  }

  function closeModal(): void {
    setAddingRecord(false);
    setModalRecord(null);
    setShowModalErrors(false);
    setShowModal(false);
  }

  async function onModalRecordChange(
    propName: string,
    propValue: string,
  ): Promise<void> {
    let rec = { ...modalRecord } as GenericListItem;

    if (propName === 'name') rec.name = propValue;
    else if (propName === 'isActive') rec.isActive = propValue === 'Yes';

    setModalRecord(rec);
    setNameError('');
  }

  async function saveRecord(): Promise<void> {
    if (!modalRecord || !modalRecord.name) {
      setShowModalErrors(true);
      return;
    }

    let duplicateRecord = records.find(
      x =>
        x.name.toLowerCase() == modalRecord.name.toLowerCase() &&
        x._id.toHexString() != modalRecord._id.toHexString(),
    );

    if (duplicateRecord) {
      setNameError('Name already exists');
      return;
    }

    let isSortOrderValid = false;
    if (sortOrderValue) {
      let sortOrderInt = parseInt(sortOrderValue);
      if (!isNaN(sortOrderInt)) {
        modalRecord.sortOrder = sortOrderInt;
        isSortOrderValid = true;
      }
    }
    if (!isSortOrderValid) {
      setShowModalErrors(true);
      return;
    }

    let orgId = getOrgId();

    // Save master-detail
    if (props.listType == 'master-detail') {
      if (!masterValue) {
        setShowModalErrors(true);
        return;
      }

      let masterSQLServerId = '';
      try {
        let mongoIdSqlId = JSON.parse(masterValue) as MongoIdSqlId;
        masterSQLServerId = mongoIdSqlId.SQLServerId ?? '';
      } catch (error) {}

      if (props.listName == ListDataTypes.TASKS) {
        let realmTask: Task | undefined = undefined;
        if (addingRecord) {
          realmTask = {
            _id: modalRecord._id,
            name: modalRecord.name,
            sortOrder: modalRecord.sortOrder,
            partition: orgId,
            isActive: modalRecord.isActive,
            isDeleted: false,
            createDateTimeStamp: new Date(),
            SQLServerId: modalRecord.SQLServerId,
            taskTypeSQLServerId: masterSQLServerId,
          };
        } else {
          realmTask = getListData<Task>(props.listKey as StorageKeyType).find(
            r => r._id.equals(modalRecord._id),
          );

          if (realmTask) {
            realmTask.name = modalRecord.name;
            realmTask.sortOrder = modalRecord.sortOrder;
            realmTask.isActive = modalRecord.isActive;
            realmTask.taskTypeSQLServerId = masterSQLServerId;
          }
        }

        if (realmTask) await upsertTask(realmTask);
      } else if (props.listName == ListDataTypes.INCIDENTCLASSIFICATIONS) {
        let realmIncidentClassification: IncidentClassification | undefined =
          undefined;
        if (addingRecord) {
          realmIncidentClassification = {
            _id: modalRecord._id,
            name: modalRecord.name,
            sortOrder: modalRecord.sortOrder,
            partition: orgId,
            isActive: modalRecord.isActive,
            isDeleted: false,
            createDateTimeStamp: new Date(),
            SQLServerId: modalRecord.SQLServerId,
            incidentTypeSQLServerId: masterSQLServerId,
          };
        } else {
          realmIncidentClassification = getListData<IncidentClassification>(
            props.listKey as StorageKeyType,
          ).find(r => r._id.equals(modalRecord._id));

          if (realmIncidentClassification) {
            realmIncidentClassification.name = modalRecord.name;
            realmIncidentClassification.sortOrder = modalRecord.sortOrder;
            realmIncidentClassification.isActive = modalRecord.isActive;
            realmIncidentClassification.incidentTypeSQLServerId =
              masterSQLServerId;
          }
        }

        if (realmIncidentClassification)
          await upsertIncidentClassification(realmIncidentClassification);
      }
    }
    // Save name-value
    else if (props.listType == 'name-value') {
      if (!itemValue) {
        setShowModalErrors(true);
        return;
      }

      if (props.listName == ListDataTypes.SEVERITYLEVELS) {
        let realmSeverityLevel: SeverityLevel | undefined = undefined;
        if (addingRecord) {
          realmSeverityLevel = {
            _id: modalRecord._id,
            name: modalRecord.name,
            value: itemValue,
            sortOrder: modalRecord.sortOrder,
            partition: orgId,
            isActive: modalRecord.isActive,
            isDeleted: false,
            createDateTimeStamp: new Date(),
            SQLServerId: modalRecord.SQLServerId,
          };
        } else {
          realmSeverityLevel = getListData<SeverityLevel>(
            props.listKey as StorageKeyType,
          ).find(r => r._id.equals(modalRecord._id));

          if (realmSeverityLevel) {
            realmSeverityLevel.name = modalRecord.name;
            realmSeverityLevel.value = itemValue;
            realmSeverityLevel.sortOrder = modalRecord.sortOrder;
            realmSeverityLevel.isActive = modalRecord.isActive;
          }
        }

        if (realmSeverityLevel) await upsertSeverityLevel(realmSeverityLevel);
      } else if (props.listName == ListDataTypes.INCIDENTPROBABILITIES) {
        let realmIncidentProbability: IncidentProbability | undefined =
          undefined;
        if (addingRecord) {
          realmIncidentProbability = {
            _id: modalRecord._id,
            name: modalRecord.name,
            value: itemValue,
            sortOrder: modalRecord.sortOrder,
            partition: orgId,
            isActive: modalRecord.isActive,
            isDeleted: false,
            createDateTimeStamp: new Date(),
            SQLServerId: modalRecord.SQLServerId,
          };
        } else {
          realmIncidentProbability = getListData<IncidentProbability>(
            props.listKey as StorageKeyType,
          ).find(r => r._id.equals(modalRecord._id));

          if (realmIncidentProbability) {
            realmIncidentProbability.name = modalRecord.name;
            realmIncidentProbability.value = itemValue;
            realmIncidentProbability.sortOrder = modalRecord.sortOrder;
            realmIncidentProbability.isActive = modalRecord.isActive;
          }
        }

        if (realmIncidentProbability)
          await upsertIncidentProbability(realmIncidentProbability);
      }
    }
    // Save basic
    else {
      let realmRecord: GenericListItem | undefined = undefined;
      if (addingRecord) {
        realmRecord = {
          _id: modalRecord._id,
          name: modalRecord.name,
          sortOrder: modalRecord.sortOrder,
          partition: orgId,
          isActive: modalRecord.isActive,
          isDeleted: modalRecord.isDeleted,
          createDateTimeStamp: new Date(),
          SQLServerId: modalRecord.SQLServerId,
        };
      } else {
        realmRecord = getListData<GenericListItem>(
          props.listKey as StorageKeyType,
        ).find(r => r._id.equals(modalRecord._id));
        if (realmRecord) {
          realmRecord.name = modalRecord.name;
          realmRecord.sortOrder = modalRecord.sortOrder;
          realmRecord.isActive = modalRecord.isActive;
        }
      }

      if (realmRecord) {
        realmRecord.dataHubVersion = Math.round(Date.now() / 1000);
        await upsertListData(
          realmRecord,
          props.listName,
          props.listKey as StorageKeyType,
          orgId,
          addingRecord,
        );
      }
    }

    toast.show('Record saved successfully', { type: 'success' });
    setAddingRecord(false);
    setModalRecord(null);
    setShowModalErrors(false);
    setShowModal(false);

    setSelectedRecord(null);
    fetchRecords();
  }

  const renderRecords = (): React.ReactElement => {
    let filteredRecords = records.filter(
      r =>
        // Search filter
        (search === '' ||
          r.name?.toLowerCase().includes(search.toLowerCase())) &&
        // Show inactive filter
        (r.isActive || showInactive),
    );

    let visibleRecords = showAll
      ? filteredRecords
      : filteredRecords.slice(0, 10);
    if (visibleRecords.length === 0) return <React.Fragment></React.Fragment>;

    return (
      <View style={ReportsStyleSheet.list}>
        <View
          style={{
            flexDirection: 'row',
            borderBottomWidth: 0.4,
            borderBottomColor: Colors.darkGreen,
          }}>
          <View style={[ReportsStyleSheet.listItemContainer, { flex: 1 }]}>
            <Text
              style={[
                ReportsStyleSheet.listItemName,
                {
                  flex: 3,
                  fontFamily: 'Poppins',
                  textTransform: 'uppercase',
                },
              ]}
              numberOfLines={2}>
              Name
            </Text>
            {props.listType == 'master-detail' && (
              <Text
                style={[
                  ReportsStyleSheet.listItemName,
                  {
                    flex: 1,
                    fontFamily: 'Poppins',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                  },
                ]}
                numberOfLines={2}>
                {masterLabel}
              </Text>
            )}
            {props.listType != 'readonly' && (
              <>
                <Text
                  style={[
                    ReportsStyleSheet.listItemName,
                    {
                      flex: 1,
                      fontFamily: 'Poppins',
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    },
                  ]}
                  numberOfLines={2}>
                  Order
                </Text>
                <Text
                  style={[
                    ReportsStyleSheet.listItemName,
                    {
                      flex: 1,
                      fontFamily: 'Poppins',
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    },
                  ]}
                  numberOfLines={2}>
                  Is Active
                </Text>
              </>
            )}
          </View>
          <View style={{ width: 49 }}></View>
        </View>
        {visibleRecords.map((record, index) => {
          return renderRecord(record, index);
        })}
        {filteredRecords.length > visibleRecords.length && (
          <Pressable
            style={({ pressed }) => [
              CommonStyleSheet.smallGreenButton,
              { alignSelf: 'center', marginTop: 24, marginBottom: 24 },
              pressed && {
                opacity: 0.8,
              },
            ]}
            onPress={() => setShowAll(true)}>
            <Text style={CommonStyleSheet.smallGreenButtonText}>Show All</Text>
          </Pressable>
        )}
      </View>
    );
  };

  const renderRecord = (
    record: GenericListItem,
    index: number,
  ): React.ReactElement => {
    return (
      <View
        key={index}
        style={{
          flexDirection: 'row',
          borderBottomWidth: 0.4,
          borderBottomColor: Colors.darkGreen,
        }}>
        <Pressable
          style={({ pressed }) => [
            ReportsStyleSheet.listItemContainer,
            { flex: 1 },
            pressed && {
              backgroundColor: Colors.darkGreenTransparent,
            },
          ]}
          onPress={() => setSelectedRecord(record)}>
          <Text
            style={[ReportsStyleSheet.listItemName, { flex: 3 }]}
            numberOfLines={2}>
            {record.name}
          </Text>
          {props.listType == 'master-detail' && (
            <Text
              style={[
                ReportsStyleSheet.listItemName,
                { flex: 1, textAlign: 'center' },
              ]}
              numberOfLines={2}>
              {record.masterLabel}
            </Text>
          )}
          {props.listType != 'readonly' && (
            <>
              <Text
                style={[
                  ReportsStyleSheet.listItemName,
                  { flex: 1, textAlign: 'center' },
                ]}
                numberOfLines={2}>
                {record.sortOrder}
              </Text>
              <Text
                style={[
                  ReportsStyleSheet.listItemName,
                  { flex: 1, textAlign: 'center' },
                ]}
                numberOfLines={2}>
                {record.isActive ? 'Active' : 'Inactive'}
              </Text>
            </>
          )}
        </Pressable>
        {renderActions(record)}
      </View>
    );
  };

  const renderActions = (record: GenericListItem): React.ReactElement => {
    if (props.listType == 'readonly') return <></>;

    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: 49,
        }}>
        {selectedRecord && selectedRecord._id.equals(record._id) && (
          <Pressable
            style={({ pressed }) => [
              pressed && {
                opacity: 0.6,
              },
              ReportsStyleSheet.draftAction,
            ]}
            onPress={viewRecord}>
            <Icon icon={'eye'} color={Colors.white} size={20} />
          </Pressable>
        )}
      </View>
    );
  };

  const renderModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {addingRecord ? 'Add Record' : 'Record View'}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={closeModal}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 36, marginHorizontal: 18 }}>
              {modalRecord ? (
                <View>
                  <DynamicTextBox
                    config={{
                      required: true,
                      maxLength: 150,
                    }}
                    label="Name"
                    value={modalRecord.name}
                    onChange={(controlId, controlTypeId, value, isValid) => {
                      onModalRecordChange('name', value);
                    }}
                    disabled={props.listType == 'readonly' || !canUpsert}
                    showError={showModalErrors}
                  />
                  <Text style={[ControlsStyleSheet.error, { top: -20 }]}>
                    {nameError}
                  </Text>
                  {props.listType == 'name-value' && (
                    <DynamicTextBox
                      config={{
                        required: true,
                        maxLength: 150,
                      }}
                      label="Value"
                      value={itemValue}
                      onChange={(controlId, controlTypeId, value, isValid) => {
                        setItemValue(value);
                      }}
                      disabled={!canUpsert}
                      showError={showModalErrors}
                    />
                  )}
                  <DynamicTextBox
                    config={{
                      required: true,
                      validationRegExp: RegexValidation.NUMBER_ONLY,
                      maxLength: 3,
                    }}
                    label="Order"
                    value={sortOrderValue}
                    onChange={(controlId, controlTypeId, value, isValid) => {
                      setSortOrderValue(value);
                    }}
                    disabled={props.listType == 'readonly' || !canUpsert}
                    showError={showModalErrors}
                  />
                  {props.listType == 'master-detail' && masterSource && (
                    <DynamicSelect
                      label={masterLabel}
                      config={{ required: true, optionSource: masterSource }}
                      value={masterValue}
                      onChange={(controlId, controlTypeId, value, isValid) =>
                        setMasterValue(value)
                      }
                      disabled={!canUpsert}
                      showError={showModalErrors}
                    />
                  )}
                  <DynamicSegmentedControl
                    config={{ segments: [{ name: 'Yes' }, { name: 'No' }] }}
                    label="Is Active"
                    value={modalRecord.isActive ? 'Yes' : 'No'}
                    onChange={(controlId, controlTypeId, value, isValid) => {
                      onModalRecordChange('isActive', value);
                    }}
                    disabled={props.listType == 'readonly' || !canUpsert}
                  />
                </View>
              ) : null}
            </ScrollView>
            {props.listType != 'readonly' && canUpsert && (
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.greenButton,
                  { alignSelf: 'center', marginTop: 'auto', marginBottom: 24 },
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                  },
                ]}
                onPress={() => saveRecord()}>
                <Text style={CommonStyleSheet.greenButtonText}>
                  {addingRecord ? 'Add Record' : 'Save'}
                </Text>
              </Pressable>
            )}
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <ScrollView>
      <View style={{ height: 260, backgroundColor: Colors.darkestGreen }}>
        <Pressable
          hitSlop={40}
          style={({ pressed }) => [
            {
              width: 42,
              height: 42,
              borderRadius: 42,
              padding: 5,
            },
            pressed && {
              backgroundColor: Colors.teal,
            },
          ]}
          onPress={() => goBack()}>
          <img
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              Icons.CHEVRON_LEFT.replaceAll('currentColor', Colors.white),
            )}`}
          />
        </Pressable>
        <Text style={ReportsStyleSheet.headerTitle}>{props.listLabel}</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 2, padding: 28 }}>
          {props.listType != 'readonly' && canUpsert && (
            <View
              style={{ marginTop: -58, marginLeft: 'auto', marginRight: 28 }}>
              <CustomPressable
                style={({ pressed }) => [
                  {
                    backgroundColor: Colors.green,
                    width: 58,
                    height: 58,
                    borderRadius: 58,
                  },
                  pressed && {
                    backgroundColor: Colors.teal,
                  },
                ]}
                onPress={addRecord}
                tooltip="Add New">
                <View style={{ marginTop: -1, marginLeft: -1 }}>
                  <Icon icon={'add'} color={Colors.white} size={60} />
                </View>
              </CustomPressable>
            </View>
          )}
          <SearchBox onChangeSearch={newSearch => setSearch(newSearch)} />
          {props.listType != 'readonly' && (
            <View
              style={{
                alignSelf: 'flex-end',
                marginHorizontal: 20,
                marginTop: 6,
              }}>
              <DynamicCheckBox
                label="Show Inactive"
                value={showInactive ? 'true' : 'false'}
                onChange={(controlId, controlTypeId, value) =>
                  setShowInactive(value === 'true')
                }
              />
            </View>
          )}
          {renderRecords()}
        </View>
        <View style={{ flex: 1 }}>
          <View style={CommonStyleSheet.bcsfFooter}>
            <Image
              source={Images.BCSF}
              style={CommonStyleSheet.bcsfFooterImage}
            />
            <Text style={CommonStyleSheet.bcsfFooterText}>
              BC <Text style={{ fontWeight: '700' }}>Forest Safety</Text>
            </Text>
          </View>
        </View>
      </View>
      {renderModal()}
    </ScrollView>
  );
};

export default EditListManagement;
