import React, { useState, useEffect, useRef } from 'react';
import { ObjectId, UUID } from 'bson';
import {
  ScrollView,
  View,
  TextInput,
  Text,
  Pressable,
  Modal,
  Linking,
} from 'react-native';
import moment from 'moment';
import MaskInput, { Masks } from 'react-native-mask-input';
import { useToast } from 'react-native-toast-notifications';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import Icon from '../Shared/Icon';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import Colors from '../../Styles/Shared/Colors';
import { useSync } from '../../Providers/SyncProvider';
import {
  LabelValue,
  LatLng,
  MongoIdSqlId,
  PersonFieldName,
} from '../../Types/DtoTypes';
import { Person } from '../../Models/RealmModels/Person';
import { EditPersonStyles } from '../../Styles/EditPersonStyles';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { ReportsStyleSheet } from '../../Styles/ReportsStyles';
import { Navigation } from '../../Constants/Navigation';
import { PERMISSIONS } from '../../Constants/AppConstants';
import { usePermission } from '../../Providers/PermissionProvider';
import ConfirmModal from '../Shared/ConfirmModal';
import { SQLUser } from '../../Models/RealmModels/SQLUser';
import { Record, RecordType } from '../Views/TrainingBreakdown';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import { PERSON_STATUSES } from '../../Constants/AppConstants';
import { SubmissionStatus } from '../../Models/RealmModels/SubmissionStatus';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { COMMON_TEMPLATES, METADATA_KEYS } from '../../Constants/AppConstants';
import { Formats } from '../../Constants/Formats';
import { UserStatus } from '../../Models/RealmModels/UserStatus';
import { SubmissionTraining } from '../../Models/RealmModels/SubmissionTraining';
import { navigationRef, currentKey } from '../Shared/RootNavigation';
import { TemplateTypes } from '../../Constants/TemplateCategoryTypes';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import DynamicSelect from '../DynamicControls/DynamicSelect';
import DynamicCheckBoxGroup from '../DynamicControls/DynamicCheckBoxGroup';
import DynamicRadioControl from '../DynamicControls/DynamicRadioControl';
import useQRCode from '../../Hooks/useQRCode';
import ProfilePicture from '../Shared/ProfilePicture';
import { Role } from '../../Models/RealmModels/Role';
import DynamicDatePicker from '../DynamicControls/DynamicDatePicker';

type EditPersonProps = {
  navigation: any;
  personId: ObjectId | null;
  addPersonWithId?: string;
};

type RoleRecord = {
  value: string;
  mongoId: ObjectId;
  label: string;
  SQLServerId: string;
  description: string;
  order: number;
};

const EditPerson = (props: EditPersonProps): React.ReactElement => {
  const {
    upsertPerson,
    upsertSQLUser,
    upsertSubmissionTraining,
    getFilteredTemplates,
    getFilteredTemplateVersions,
    getPeople,
    getSQLUsers,
    getUserStatus,
    getSubmissionTrainings,
    getSubmissionStatuses,
    getOrgId,
    getRealmApp,
    getRoles,
    people,
  } = useSync();
  const [roles, setRoles] = useState<RoleRecord[]>([]);
  const [maxHeight, setMaxHeight] = useState(120);
  const [addingPerson, setAddingPerson] = useState(false);
  const [person, setPerson] = useState<null | Person>(null);
  const [personUser, setPersonUser] = useState<null | SQLUser>(null);
  const [trainingRecords, setTrainingRecords] = useState<Record[]>([]);
  const [access, setAccess] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [firstNameError, setFirstNameError] = useState(true);
  const [lastNameError, setLastNameError] = useState(true);
  const [peopleTypeError, setPeopleTypeError] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [phoneDirectError, setPhoneDirectError] = useState('');
  const [faxError, setFaxError] = useState('');
  const [employeeNumberError, setEmployeeNumberError] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [peopleOptions, setPeopleOptions] = useState<LabelValue<ObjectId>[]>(
    [],
  );
  const [canUpdate, setCanUpdate] = useState(false);
  const [currentUserRoleOrder, setCurrentUserRoleOrder] = useState(0);
  const [selectedRoleOrder, setSelectedRoleOrder] = useState(0);
  const { hasPermission, getCurrentUserRoles } = usePermission();

  const [myKey, setMyKey] = useState('');
  const [navKey, setNavKey] = useState('');

  const { renderQRCode } = useQRCode();
  const toast = useToast();

  const nameRegExp = "^[a-zA-Z0-9áéíóúÁÉÍÓÚ '._-]+$";

  const updateModalCommand = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  useEffect(() => {
    if (props.navigation) {
      setMyKey(currentKey());
      setNavKey(currentKey());

      if (navigationRef.current) {
        const unsubscribe = navigationRef.current.addListener('state', () => {
          setNavKey(currentKey());
        });

        return unsubscribe;
      }
    }
  }, []);

  //Get roles from DB, set in a string array and sort it.
  useEffect(() => {
    const getFirsRoles = async () => {
      const firsRoles: Role[] = await getRoles();

      if (firsRoles) {
        const rolesArray: RoleRecord[] = [];

        for (const mongoRole of firsRoles) {
          const valueString = JSON.stringify({
            mongoId: mongoRole._id,
            label: mongoRole.name,
            SQLServerId: mongoRole.SQLServerId,
          });

          let selectedRoleObj: RoleRecord = {
            value: valueString,
            mongoId: mongoRole._id,
            label: mongoRole.name,
            SQLServerId: mongoRole.SQLServerId,
            description: mongoRole.description,
            order: mongoRole.sortOrder,
          };

          rolesArray.push(selectedRoleObj);
        }

        rolesArray.sort((a, b) => {
          return b.order - a.order;
        });

        setRoles(rolesArray);

        let currentRoles = getCurrentUserRoles();

        let currentFirsRoles = firsRoles.filter(x =>
          currentRoles.includes(x.name),
        );

        if (currentFirsRoles && currentFirsRoles.length)
          setCurrentUserRoleOrder(currentFirsRoles[0].sortOrder);
      }
    };

    getFirsRoles();
  }, []);

  useEffect(() => {
    if (props.personId && myKey === navKey)
      fetchTrainingRecords(props.personId);
  }, [myKey, navKey]);

  useEffect(() => {
    let updatePeople = hasPermission(PERMISSIONS.PEOPLE_UPDATE);

    setCanUpdate(updatePeople);
  }, []);

  useEffect(() => {
    const fetchPeople = async () => {
      let fetchedPeople = await getPeople({});

      let mappedPeople: LabelValue<ObjectId>[] = fetchedPeople.map(x => ({
        label: x.firstName + ' ' + x.lastName,
        value: x._id,
      }));

      setPeopleOptions(mappedPeople);
    };

    fetchPeople();
  }, []);

  useEffect(() => {
    //No project site id, adding new project site
    if (!props.personId) {
      setAddingPerson(true);
      let newPerson = getEmptyPerson(props.addPersonWithId);
      setPerson(newPerson);
      setPersonUser(null);
      setAccess('No');
      return;
    }

    const loadPerson = async () => {
      let realmPerson = (await getPeople({ _id: props.personId! }, 1))[0];

      if (!realmPerson) {
        //Log error project not found
        goBack();
        return;
      }

      setPerson(realmPerson);

      let filteredUser = (await getSQLUsers())
        .filter(
          u => !u.isDeleted && u.SQLServerPersonId === realmPerson.SQLServerId,
        )
        .sort((a, b) => a.dataHubVersion - b.dataHubVersion)[0];
      let hasAccess =
        filteredUser &&
        filteredUser.statusObj &&
        (filteredUser.statusObj.name === PERSON_STATUSES.ACCESS ||
          filteredUser.statusObj.name === PERSON_STATUSES.PENDING);
      setPersonUser(filteredUser ?? null);
      setAccess(hasAccess ? 'Yes' : 'No');

      if (filteredUser?.SQLServerRoleIds) {
        //default to first role
        let mongoRole = (await getRoles()).filter(x =>
          filteredUser?.SQLServerRoleIds!.includes(x.SQLServerId),
        )[0];

        let selectedRoleJson = JSON.stringify({
          mongoId: mongoRole._id,
          label: mongoRole.name,
          SQLServerId: mongoRole.SQLServerId,
        });

        setSelectedRole(selectedRoleJson);
      }

      setFirstNameError(!realmPerson.firstName);
      setLastNameError(!realmPerson.lastName);
    };

    if (people.length > 0) {
      loadPerson();
      fetchTrainingRecords(props.personId);
    }
  }, [props.personId, people]);

  useEffect(() => {
    if (selectedRole) {
      const parsedRole = JSON.parse(selectedRole);
      let matchedRoles = roles.filter(x => x.label == parsedRole.label);
      if (matchedRoles && matchedRoles.length)
        setSelectedRoleOrder(matchedRoles[0].order);
    }
  }, [selectedRole]);

  const fetchTrainingRecords = async (personId: ObjectId) => {
    let filteredRecords = (await getSubmissionTrainings()).filter(
      s =>
        s.submissionStatus.name === SubmissionStatuses.SUBMITTED &&
        s.person.id?.equals(personId),
    );

    let mappedRecords: Record[] = [];
    filteredRecords.forEach(s => {
      let expiryDate: Date | undefined = undefined;
      if (s.metadataJSON) {
        let meta = JSON.parse(s.metadataJSON);

        let expiryDateMeta = meta[METADATA_KEYS.EXPIRY_DATE]
          ? meta[METADATA_KEYS.EXPIRY_DATE]
          : '';
        if (expiryDateMeta) {
          let momentDate = moment(expiryDateMeta, Formats.BACKEND_DATE);
          if (momentDate.isValid()) expiryDate = momentDate.toDate();
        }
      }

      let status = 'Valid';
      if (expiryDate) {
        if (expiryDate <= moment().startOf('day').toDate()) status = 'Expired';
        else if (expiryDate <= moment().startOf('day').add(1, 'month').toDate())
          status = 'Expiring Soon';
      } else status = 'Never Expires';

      let recordType: RecordType | undefined = undefined;
      if (s.trainingType.id && s.trainingType.name)
        recordType = {
          id: s.trainingType.id,
          name: s.trainingType.name ?? '',
          source: 'training',
        };
      else if (s.certificateType.id)
        recordType = {
          id: s.certificateType.id,
          name: s.certificateType.name ?? '',
          source: 'certificate',
        };

      if (recordType)
        mappedRecords.push({
          id: s._id,
          person: {
            id: s.person.id,
            name: s.person.firstName + ' ' + s.person.lastName,
          },
          status: status,
          expiryDate: expiryDate,
          type: recordType,
          icon: '',
        });
    });

    setTrainingRecords(mappedRecords);
  };

  const checkEmailExists = async () => {
    if (!person?.email) return false;

    let existingEmail = await getPeople({ email: person?.email });
    if (existingEmail.length > 1 && !existingEmail[0]._id.equals(person._id))
      return true;

    return false;
  };

  const checkEmployeeNumberExists = async () => {
    if (!person?.employeeNumber) return false;

    let existingNumber = (await getPeople({})).filter(
      x => x.employeeNumber?.toLowerCase() == person.employeeNumber!.toLowerCase(),
    );
    if (existingNumber.some(x => !x._id.equals(person._id))) return true;

    return false;
  };

  const getEmptyPerson = (withId?: string) => {
    let orgid = getOrgId();

    let projectDto: Person = {
      _id: new ObjectId(withId),
      isActive: true,
      isDeleted: false,
      firstName: '',
      lastName: '',
      partition: orgid.toString(),
      dataHubVersion: Math.round(Date.now() / 1000),
      createDateTimeStamp: new Date(),
      SQLServerId: new UUID().toHexString().toUpperCase(),
      jobId: '',
      jobSQLServerId: '',
      peopleTypeId: '',
      peopleTypeSQLServerId: '',
    };

    return projectDto;
  };

  const onSave = async () => {
    if (!canUpdate && !addingPerson) {
      //No update access
      updateModalCommand.current.openModal();
      return;
    }

    setShowErrors(true);

    let checkEmail = await checkEmailExists();
    setEmailExists(checkEmail);

    let phoneErrorText = '';
    if (person?.phone && person?.phone.length != 10)
      phoneErrorText = 'Please enter a valid phone number';

    setPhoneError(phoneErrorText);

    let phoneDirectErrorText = '';
    if (person?.phoneDirect && person?.phoneDirect.length != 10)
      phoneDirectErrorText = 'Please enter a valid phone number';

    setPhoneDirectError(phoneDirectErrorText);

    let faxErrorText = '';
    if (person?.fax && person?.fax.length != 10)
      faxErrorText = 'Please enter a valid fax number';

    setFaxError(faxErrorText);

    let employeeNumberExists = await checkEmployeeNumberExists();
    setEmployeeNumberError(employeeNumberExists);

    if (
      firstNameError ||
      lastNameError ||
      peopleTypeError ||
      emailError ||
      checkEmail ||
      phoneErrorText ||
      phoneDirectErrorText ||
      faxErrorText ||
      employeeNumberExists
    )
      return;

    if (
      (addingPerson && (!person!.email || person!.email === '')) ||
      (access === 'Yes' && !personUser) ||
      (access !== 'Yes' &&
        personUser &&
        personUser.statusObj.name == PERSON_STATUSES.ACCESS)
    )
      setShowConfirmModal(true);
    else savePerson();
  };

  const savePerson = async () => {
    person!.dataHubVersion = Math.round(Date.now() / 1000);
    await upsertPerson(person!);

    let newSQLServerRoleIds: string[] = [];

    if (selectedRole) {
      newSQLServerRoleIds.push(
        (JSON.parse(selectedRole) as MongoIdSqlId).SQLServerId!,
      );
    }

    // Grant Access
    const userStatuses = await getUserStatus();
    const pending: UserStatus = userStatuses.find(
      s => s.name == PERSON_STATUSES.PENDING,
    )!;
    const disabled: UserStatus = (await getUserStatus()).find(
      s => s.name == PERSON_STATUSES.DISABLED,
    )!;
    const accessStatus: UserStatus = (await getUserStatus()).find(
      s => s.name == PERSON_STATUSES.ACCESS,
    )!;

    if (!personUser && access === 'Yes' && person && person.email) {
      let currentUser = getRealmApp().currentUser;
      let newUser: SQLUser = {
        _id: new ObjectId(),
        firstName: person.firstName,
        lastName: person.lastName,
        email: person.email,
        ehsId: '',
        partition: person.partition,
        isActive: true,
        isDeleted: false,
        createDateTimeStamp: new Date(),
        SQLServerId: new UUID().toHexString().toUpperCase(),
        SQLServerPersonId: person.SQLServerId,
        SQLServerInvitedByPersonId: currentUser?.profile
          .personId!.toString()
          .toUpperCase(),
        dataHubVersion: Math.round(Date.now() / 1000),
        statusObj: { id: pending._id, name: pending.name },
        SQLServerRoleIds: newSQLServerRoleIds,
      };

      await upsertSQLUser(newUser);
    } else if (personUser && personUser.ehsId && access == 'Yes') {
      personUser.statusObj = { id: accessStatus._id, name: accessStatus.name };
      personUser.dataHubVersion = Math.round(Date.now() / 1000);
      personUser.SQLServerRoleIds = newSQLServerRoleIds;
      await upsertSQLUser(personUser);
    } else if (personUser && !personUser.ehsId && access == 'Yes') {
      personUser.statusObj = { id: pending._id, name: pending.name };
      personUser.dataHubVersion = Math.round(Date.now() / 1000);
      personUser.SQLServerRoleIds = newSQLServerRoleIds;
      await upsertSQLUser(personUser);
    } else if (personUser && access == 'No') {
      personUser.statusObj = { id: disabled._id, name: disabled.name };
      personUser.dataHubVersion = Math.round(Date.now() / 1000);
      personUser.SQLServerRoleIds = newSQLServerRoleIds;

      //Only delete if it's pending (only invites can be deleted)
      if (personUser.statusObj.name === PERSON_STATUSES.PENDING)
        personUser.isDeleted = true;
      await upsertSQLUser(personUser);
    }

    goBack();
    toast.show('Person saved successfully', { type: 'success' });
  };

  const goBack = () => {
    props.navigation.pop();
  };

  const goToTrainingAndCertificates = async (record?: Record) => {
    if (record)
      props.navigation.push(Navigation.TRAININGFORM, {
        submissionId: record.id,
      });
    else {
      let template = (await getFilteredTemplates()).find(
        t => t.templateType.name === TemplateTypes.TRAINING_BREAKDOWN,
      );

      if (person && template) {
        let templateId = template._id;
        let versions = (await getFilteredTemplateVersions())
          .filter(x => x.templateId.equals(templateId))
          .sort(x => x.version)
          .reverse()
          .map(x => ({ _id: x._id, name: x.name }));

        let newDraftId: ObjectId = new ObjectId();
        const draftStatus: SubmissionStatus = (
          await getSubmissionStatuses()
        ).find(x => x.name == SubmissionStatuses.DRAFT)!;

        let currentUser = getRealmApp().currentUser;
        let org = '';
        let currentEmail = '';
        if (currentUser && currentUser.profile) {
          if (typeof currentUser.profile.organisation === 'string') {
            let organisation = JSON.parse(currentUser.profile.organisation);
            org = organisation.Id.toString();
          }
          if (typeof currentUser.profile.email === 'string') {
            currentEmail = currentUser.profile.email;
          }
        }

        let newSubmission: SubmissionTraining = {
          _id: newDraftId,
          partition: org,
          createdBy: currentEmail as string,
          updatedBy: currentEmail as string,
          templateVersion: {
            _id: versions[0]._id,
            name: versions[0].name,
          },
          templateType: {
            _id: template!.templateType._id,
            name: template!.templateType.name,
          },
          submissionStatus: {
            _id: draftStatus._id,
            name: draftStatus.name,
          },
          SQLServerId: new UUID().toHexString(),
          answers: [],
          metadataJSON: '',
          createDateTimeStamp: new Date(),
          updateDateTimeStamp: new Date(),
          person: {
            id: person._id,
            firstName: person.firstName,
            lastName: person.lastName,
            SQLServerPersonId: person.SQLServerId,
          },
          trainingType: {},
          certificateType: {},
        };

        await upsertSubmissionTraining(newSubmission);

        if (newDraftId == null) throw Error('Error: create draft failed!');

        props.navigation.navigate(Navigation.TRAININGFORM, {
          submissionId: newDraftId,
        });
      }
    }
  };

  const goToPersonProfile = (ehsId: string) => {
    let profileUrl = process.env.REACT_APP_PUBLIC_PROFILE_URL;
    let url = profileUrl + '?ehsId=' + ehsId;
    if (url) Linking.openURL(url);
  };

  const getMongoSqlId = (valueJson: string): MongoIdSqlId | null => {
    let mongoSqlId = null;

    if (valueJson) {
      try {
        mongoSqlId = JSON.parse(valueJson) as MongoIdSqlId;
      } catch (error) {
        console.log('Error: Bad object serialization');
      }
    }

    return mongoSqlId;
  };

  //Typescript complains about indexing props by string so checking it manually
  //Maybe there's an easier way to do this?
  const onChange = (propName: PersonFieldName, propValue: string) => {
    if (propName === 'jobId') {
      let mongoSqlId = getMongoSqlId(propValue ?? '');
      if (mongoSqlId) {
        person!.jobId = mongoSqlId.mongoId;
        person!.jobSQLServerId = mongoSqlId.SQLServerId;
      } else {
        person!.jobId = undefined;
        person!.jobSQLServerId = undefined;
      }
    } else if (propName === 'supervisorId') {
      let mongoSqlId = getMongoSqlId(propValue ?? '');
      if (mongoSqlId) {
        person!.supervisorId = mongoSqlId.mongoId;
        person!.supervisorSQLServerId = mongoSqlId.SQLServerId;
      } else {
        person!.supervisorId = undefined;
        person!.supervisorSQLServerId = undefined;
      }
    } else if (propName === 'peopleTypeId') {
      let mongoSqlId = getMongoSqlId(propValue ?? '');
      if (mongoSqlId) {
        person!.peopleTypeId = mongoSqlId.mongoId;
        person!.peopleTypeSQLServerId = mongoSqlId.SQLServerId;
      } else {
        person!.peopleTypeId = undefined;
        person!.peopleTypeSQLServerId = undefined;
      }
    } else if (propName === 'hireDate') {
      let momentDate = moment(propValue, Formats.BACKEND_DATE);
      if (momentDate.isValid())
        person!.hireDate = momentDate.startOf('day').toDate();
      else person!.hireDate = undefined;
    } else if (propName === 'inactiveDate') {
      let momentDate = moment(propValue, Formats.BACKEND_DATE);
      if (momentDate.isValid())
        person!.inactiveDate = momentDate.startOf('day').toDate();
      else person!.inactiveDate = undefined;
    } else {
      person![propName] = propValue;
    }

    let newPerson = { ...person } as Person;
    setPerson(newPerson);

    if (propName === 'email' && propValue === '') setAccess('No');
  };

  const onChangeProfilePicture = async (
    photo: string,
    photoThumbnail: string,
  ) => {
    if (!addingPerson && person) {
      let realmPerson = (await getPeople({})).find(
        x => x.SQLServerId.toLowerCase() == person.SQLServerId.toLowerCase(),
      );
      if (realmPerson) {
        realmPerson.photo = photo;
        realmPerson.photoThumbnail = photoThumbnail;
        await upsertPerson(realmPerson);
        toast.show('Profile picture saved successfully', {
          type: 'success',
        });
      }
    }

    let newPerson = { ...person } as Person;
    newPerson.photo = photo;
    newPerson.photoThumbnail = photoThumbnail;
    setPerson(newPerson);
  };

  const renderLoading = (): React.ReactElement => {
    return (
      <View
        style={{
          position: 'absolute',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.4)',
        }}>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            style={{
              color: Colors.white,
              fontSize: 16,
              fontWeight: '600',
            }}>
            Loading Person Info...
          </Text>
        </View>
      </View>
    );
  };

  const renderRecord = (record: Record, index: number): React.ReactElement => {
    return (
      <Pressable
        key={index}
        style={({ pressed }) => [
          ReportsStyleSheet.listItemContainer,
          pressed && {
            backgroundColor: Colors.darkGreenTransparent,
          },
        ]}
        onPress={() => goToTrainingAndCertificates(record)}>
        <Icon
          icon={
            record.type.source === 'training' ? 'training' : 'certification'
          }
          color={Colors.darkestGreen}
          size={24}
        />
        <Text
          style={[ReportsStyleSheet.listItemName, { paddingLeft: 12 }]}
          numberOfLines={1}>
          {record.type.name}
        </Text>
        {renderExpiryDate(record)}
        <Icon icon={'list-item-nav'} color={Colors.darkestGreen} size={24} />
      </Pressable>
    );
  };

  const renderExpiryDate = (record: Record): React.ReactElement => {
    let date = record.expiryDate
      ? moment(record.expiryDate).format('MMM DD YYYY')
      : '';

    let backgroundColor = '';
    let color = '';

    switch (record.status) {
      case 'Valid':
      case 'Never Expires':
        backgroundColor = Colors.lightgray;
        color = Colors.darkGreen;
        break;
      case 'Expiring Soon':
        backgroundColor = Colors.yellow;
        color = Colors.darkGreen;
        break;
      case 'Expired':
        backgroundColor = Colors.red;
        color = Colors.white;
        break;
      default:
        break;
    }

    return (
      <View
        style={[
          ReportsStyleSheet.listItemDateContent,
          { backgroundColor: backgroundColor, marginRight: 12 },
        ]}>
        <Text style={[ReportsStyleSheet.listItemDate, { color: color }]}>
          {date ? date : 'Never Expires'}
        </Text>
      </View>
    );
  };

  const renderConfirmModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showConfirmModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View
            style={[ControlsStyleSheet.groupSelectorModal, { height: 'auto' }]}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                {addingPerson && (!person?.email || person?.email === '')
                  ? 'Missing Email'
                  : 'Access'}
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setShowConfirmModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 20 }}>
              {addingPerson && (!person?.email || person?.email === '') && (
                <View
                  style={{
                    alignItems: 'center',
                    gap: 24,
                    paddingVertical: 24,
                  }}>
                  <Text
                    style={[
                      ControlsStyleSheet.label,
                      { fontSize: 16, textTransform: 'none' },
                    ]}>
                    Without an email address, this person can not be granted
                    access to FIRS, nor receive notifications from FIRS.
                  </Text>
                </View>
              )}
              {access === 'Yes' && !personUser && (
                <View
                  style={{
                    alignItems: 'center',
                    gap: 24,
                    paddingVertical: 24,
                  }}>
                  <Text style={[ControlsStyleSheet.label, { fontSize: 14 }]}>
                    You are granting access to:
                  </Text>
                  <Text
                    style={[
                      ControlsStyleSheet.label,
                      { fontSize: 14, textTransform: 'none' },
                    ]}>
                    {person?.email}
                  </Text>
                </View>
              )}
              {access !== 'Yes' && personUser && (
                <View
                  style={{
                    alignItems: 'center',
                    gap: 24,
                    paddingVertical: 24,
                  }}>
                  <Text style={[ControlsStyleSheet.label, { fontSize: 14 }]}>
                    You are disabling access to:
                  </Text>
                  <Text
                    style={[
                      ControlsStyleSheet.label,
                      { fontSize: 14, textTransform: 'none' },
                    ]}>
                    {person?.email}
                  </Text>
                </View>
              )}
              <View
                style={{ alignItems: 'center', gap: 24, paddingVertical: 24 }}>
                <Text style={[ControlsStyleSheet.label, { fontSize: 14 }]}>
                  Do you want to continue?
                </Text>
              </View>
            </ScrollView>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingVertical: 20,
              }}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.whiteButton,
                  { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => setShowConfirmModal(false)}>
                <Text style={CommonStyleSheet.whiteButtonText}>No</Text>
              </Pressable>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.greenButton,
                  { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => savePerson()}>
                <Text style={CommonStyleSheet.greenButtonText}>Yes</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <ScrollView>
      <View>
        {!person ? (
          renderLoading()
        ) : (
          <>
            <View
              style={{
                backgroundColor: Colors.darkGreen,
                paddingTop: 20,
              }}>
              <View style={DynamicPageStyleSheet.header}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <Text style={DynamicPageStyleSheet.headerText}>
                    {addingPerson
                      ? 'Add a Person'
                      : canUpdate
                      ? 'Edit Person'
                      : 'Person View'}
                  </Text>
                  <View
                    style={{ paddingRight: 10, justifyContent: 'flex-start' }}>
                    <Pressable
                      style={({ pressed }) => [
                        pressed && {
                          opacity: 0.4,
                        },
                      ]}
                      onPress={goBack}>
                      <Icon
                        icon={'close-circle'}
                        color={Colors.white}
                        size={24}
                      />
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
            <View style={EditPersonStyles.body}>
              <ScrollView keyboardShouldPersistTaps="handled">
                <ConfirmModal
                  title="No Access"
                  message="You do not have access to update people"
                  okClick={() => {}}
                  okText="Ok"
                  setOpenModal={(func: () => void) => {
                    updateModalCommand.current.setOpenModal(func);
                  }}
                />
                <View
                  style={{
                    flexDirection: 'row',
                    gap: 64,
                  }}>
                  <ProfilePicture
                    profileId={person.SQLServerId}
                    editable={canUpdate}
                    onChange={onChangeProfilePicture}
                  />
                  {access && personUser && personUser.ehsId ? (
                    <View>
                      <Pressable
                        style={({ pressed }) => [
                          pressed && {
                            opacity: 0.8,
                          },
                        ]}
                        onPress={() => goToPersonProfile(personUser.ehsId)}>
                        <View
                          style={{
                            alignSelf: 'center',
                            borderColor: Colors.green,
                            borderRadius: 8,
                            borderWidth: 2,
                          }}>
                          <View
                            style={[
                              CommonStyleSheet.profileQRCode,
                              { marginBottom: 0 },
                            ]}>
                            {renderQRCode(personUser.ehsId)}
                          </View>
                        </View>
                        <Text
                          style={[
                            ControlsStyleSheet.label,
                            {
                              alignSelf: 'center',
                              fontSize: 14,
                              textTransform: 'none',
                              marginTop: 2,
                            },
                          ]}>
                          EHS ID: {personUser.ehsId}
                        </Text>
                      </Pressable>
                    </View>
                  ) : null}
                </View>
                <DynamicTextBox
                  config={{
                    required: true,
                    validationRegExp: nameRegExp,
                    placeholder: 'Eg. John',
                    maxLength: 50,
                  }}
                  label={'First Name'}
                  value={person?.firstName}
                  onChange={(controlId, controlTypeId, value, isValid) => {
                    onChange('firstName', value);
                    setFirstNameError(!isValid);
                  }}
                  showError={showErrors}
                />
                <DynamicTextBox
                  config={{
                    required: true,
                    validationRegExp: nameRegExp,
                    placeholder: 'Eg. Smith',
                    maxLength: 50,
                  }}
                  label={'Last Name'}
                  value={person?.lastName}
                  onChange={(controlId, controlTypeId, value, isValid) => {
                    onChange('lastName', value);
                    setLastNameError(!isValid);
                  }}
                  showError={showErrors}
                />
                <DynamicTextBox
                  config={{
                    validationRegExp:
                      '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
                    placeholder: 'Eg. john@gmail.com',
                    maxLength: 50,
                  }}
                  label={'Email'}
                  value={person?.email}
                  disabled={personUser != null}
                  onChange={(controlId, controlTypeId, value, isValid) => {
                    onChange('email', value);
                    setEmailError(!isValid);
                  }}
                  showError={showErrors}
                />
                <View>
                  <Text style={ControlsStyleSheet.label}>Cell Phone</Text>
                </View>
                <MaskInput
                  style={ControlsStyleSheet.input}
                  placeholder="Eg. (250) 555-5555"
                  placeholderTextColor={Colors.darkGreen}
                  value={person?.phone}
                  onChangeText={(masked, unmasked) =>
                    onChange('phone', unmasked)
                  }
                  mask={Masks.USA_PHONE}
                />
                <Text style={ControlsStyleSheet.error}>{phoneError}</Text>
                <View>
                  <Text style={ControlsStyleSheet.label}>Office Telephone</Text>
                </View>
                <MaskInput
                  style={ControlsStyleSheet.input}
                  placeholder="Eg. (250) 555-5555"
                  placeholderTextColor={Colors.darkGreen}
                  value={person?.phoneDirect}
                  onChangeText={(masked, unmasked) =>
                    onChange('phoneDirect', unmasked)
                  }
                  mask={Masks.USA_PHONE}
                />
                <Text style={ControlsStyleSheet.error}>{phoneDirectError}</Text>
                <View>
                  <Text style={ControlsStyleSheet.label}>Fax</Text>
                </View>
                <MaskInput
                  style={ControlsStyleSheet.input}
                  placeholder="Eg. (250) 555-5555"
                  placeholderTextColor={Colors.darkGreen}
                  value={person?.fax}
                  onChangeText={(masked, unmasked) => onChange('fax', unmasked)}
                  mask={Masks.USA_PHONE}
                />
                <Text style={ControlsStyleSheet.error}>{faxError}</Text>
                <DynamicAutocomplete
                  label={'Supervisor'}
                  value={
                    person.supervisorId
                      ? JSON.stringify({
                          mongoId: person.supervisorId,
                          SQLServerId: person.supervisorSQLServerId,
                        })
                      : ''
                  }
                  config={{ optionSource: ListDataTypes.PEOPLE }}
                  disabled={!canUpdate}
                  onChange={(controlId, controlTypeId, value) =>
                    onChange('supervisorId', value?.toString())
                  }
                  required={false}
                />
                <DynamicAutocomplete
                  label={'Job Title'}
                  value={
                    person.jobId
                      ? JSON.stringify({
                          mongoId: person.jobId,
                          SQLServerId: person.jobSQLServerId,
                        })
                      : ''
                  }
                  config={{ optionSource: ListDataTypes.JOBS }}
                  disabled={!canUpdate}
                  onChange={(controlId, controlTypeId, value) =>
                    onChange('jobId', value?.toString())
                  }
                  required={false}
                />
                <DynamicSelect
                  label={'Type of Relationship'}
                  value={
                    person.peopleTypeId
                      ? JSON.stringify({
                          mongoId: person.peopleTypeId,
                          SQLServerId: person.peopleTypeSQLServerId,
                        })
                      : ''
                  }
                  config={{
                    optionSource: ListDataTypes.PEOPLETYPES,
                    required: true,
                  }}
                  disabled={!canUpdate}
                  onChange={(controlId, controlTypeId, value, isValid) => {
                    onChange('peopleTypeId', value?.toString());
                    setPeopleTypeError(!isValid);
                  }}
                  required={false}
                  showError={showErrors}
                />
                <DynamicTextBox
                  label="Employee Number"
                  value={person.employeeNumber ?? ''}
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('employeeNumber', value);
                  }}
                  disabled={!canUpdate}
                />
                <DynamicDatePicker
                  label="Hire Date"
                  value={
                    person.hireDate
                      ? moment.utc(person.hireDate).format(Formats.BACKEND_DATE)
                      : ''
                  }
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('hireDate', value);
                  }}
                  disabled={!canUpdate}
                />
                <DynamicDatePicker
                  label="Inactive Date"
                  value={
                    person.inactiveDate
                      ? moment
                          .utc(person.inactiveDate)
                          .format(Formats.BACKEND_DATE)
                      : ''
                  }
                  onChange={(controlId, controlTypeId, value) => {
                    onChange('inactiveDate', value);
                  }}
                  disabled={!canUpdate}
                />
                <DynamicSegmentedControl
                  label="Access"
                  config={{
                    segments: [{ name: 'Yes' }, { name: 'No' }],
                  }}
                  value={access}
                  onChange={(controlId, controlTypeId, value) =>
                    setAccess(value)
                  }
                  disabled={
                    !person?.email || person?.email === '' ? true : false
                  }
                />
                {access === 'Yes' && (
                  <View>
                    <Text
                      style={[
                        ControlsStyleSheet.checkBoxText,
                        { paddingTop: 10, paddingBottom: 10 },
                      ]}>
                      {'Role'}
                    </Text>
                    <View
                      style={[
                        ControlsStyleSheet.checkBoxContainerRoles,
                        { paddingBottom: 20 },
                      ]}>
                      {roles.map((role, index) => {
                        const radio = role;
                        const disabled =
                          currentUserRoleOrder < radio.order ||
                          currentUserRoleOrder < selectedRoleOrder;
                        return (
                          <Pressable
                            key={radio.label}
                            disabled={disabled}
                            style={({ pressed }) => [
                              ControlsStyleSheet.checkBoxContainerDescription,
                              {
                                backgroundColor:
                                  radio.value === selectedRole
                                    ? Colors.lightTeal
                                    : 'white',
                                height: maxHeight,
                              }, // Change background color
                              pressed && { opacity: 0.8 },
                              disabled && {
                                opacity: 0.5,
                              },
                            ]}
                            onPress={() => setSelectedRole(radio.value)}>
                            <View
                              style={[
                                ControlsStyleSheet.checkBox,
                                radio.value === selectedRole && {
                                  backgroundColor: Colors.teal,
                                  borderColor: Colors.teal,
                                },
                              ]}>
                              <Icon
                                icon={'checked'}
                                color={
                                  radio.value === selectedRole
                                    ? Colors.white
                                    : Colors.whiteTransparent
                                }
                                size={16}
                              />
                            </View>
                            <View style={{ flex: 1, marginLeft: 10 }}>
                              <Text
                                style={[
                                  ControlsStyleSheet.checkBoxText,
                                  {
                                    fontFamily: 'Lato',
                                    fontSize: 16,
                                    paddingBottom: 10,
                                  },
                                ]}>
                                {radio.label}
                              </Text>
                              <Text
                                style={[
                                  ControlsStyleSheet.checkBoxText,
                                  {
                                    fontFamily: 'Lato',
                                    fontWeight: 'normal',
                                    marginRight: 10,
                                    fontSize: 14,
                                  },
                                ]}>
                                {radio.description}
                              </Text>
                            </View>
                          </Pressable>
                        );
                      })}
                    </View>
                  </View>
                )}
                <View style={{ padding: 10 }}>
                  <Pressable
                    style={CommonStyleSheet.greenButton}
                    android_ripple={{ color: Colors.whiteTransparent }}
                    onPress={() => {
                      onSave();
                    }}>
                    <Text style={CommonStyleSheet.greenButtonText}>
                      {addingPerson ? 'Create Person' : 'Save Person'}
                    </Text>
                  </Pressable>
                </View>
                {emailExists ? (
                  <Text
                    style={[ControlsStyleSheet.error, { alignSelf: 'center' }]}>
                    Email already exists
                  </Text>
                ) : null}
                {employeeNumberError ? (
                  <Text
                    style={[ControlsStyleSheet.error, { alignSelf: 'center' }]}>
                    Employee Number already exists
                  </Text>
                ) : null}
                {!addingPerson ? (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginVertical: 24,
                      }}>
                      <Text style={ControlsStyleSheet.labelLarge}>
                        Training And Certificates
                      </Text>
                      <Pressable
                        style={({ pressed }) => [
                          ControlsStyleSheet.linkRecordAddButton,
                          pressed && {
                            backgroundColor: Colors.darkGreenTransparent,
                          },
                        ]}
                        onPress={() => goToTrainingAndCertificates()}>
                        <Icon
                          icon={'plus'}
                          color={Colors.darkestGreen}
                          size={24}
                        />
                      </Pressable>
                    </View>
                    <View style={{ marginBottom: 24 }}>
                      {trainingRecords.map((record, index) => {
                        return renderRecord(record, index);
                      })}
                    </View>
                  </>
                ) : null}
              </ScrollView>
            </View>
          </>
        )}
      </View>
      {renderConfirmModal()}
    </ScrollView>
  );
};

export default EditPerson;
