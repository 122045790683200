import React, { useState, useEffect, useRef } from 'react';
import { ObjectId } from 'bson';
import { View, Text, Pressable, Modal, ScrollView } from 'react-native';
import { usePermission } from '../../Providers/PermissionProvider';
import {
  LOCK_STATUS,
  METADATA_KEYS,
  PERMISSIONS,
} from '../../Constants/AppConstants';
import Colors from '../../Styles/Shared/Colors';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import ProgressBar from './ProgressBar';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import { Images } from '../../Constants/Images';
import Icon from '../Shared/Icon';
import { Submission } from '../../Models/RealmModels/Submission';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import AddReportToBundle from '../Shared/AddReportToBundle';
import { TemplateTypes } from '../../Constants/TemplateCategoryTypes';
import { useSync } from '../../Providers/SyncProvider';
import DynamicPDF from './DynamicPDF';
import { Task } from '../../Models/RealmModels/Task';
import ConfirmModal from '../Shared/ConfirmModal';
import { Navigation } from '../../Constants/Navigation';

type FormHeaderProps = {
  navigation?: any;
  submissionId: string;
  submissionType?: 'submission' | 'submissionTraining';
  submissionStatus?: string;
  formName: string;
  formIcon?: string;
  pageCount: number;
  currentPage: number;
  creator?: string;
  creatorLabel?: string;
  updatedBy?: string;
  updatedLabel?: string;
  onStatusLockChange?: (newSubmissionStatusLock: string) => void; // Optional callback
  pagePressed: (pageNumber: number) => void;
  closePressed: () => void;
  validations: {
    controlId: number;
    Label: string;
    page: number;
    isValid: boolean;
  }[];
  showErrors: { page: number; show: boolean }[];
  showMoreActions: boolean;
  taskStatus?: string;
  dismissReason?: string;
};

const FormHeader = (props: FormHeaderProps): React.ReactElement => {
  const [isShowingActionsModal, setIsShowingActionsModal] = useState(false);
  const {
    submissions,
    submissionTrainings,
    getSubmissions,
    getSubmissionTrainings,
    upsertSubmission,
    upsertSubmissionTraining,
    existSubmissionInBundle,
    getSubmissionLinks,
    getFilteredTemplateVersions,
  } = useSync();
  const [addToBundlePermissions, setAddToBundlePermissions] = useState(false);
  const [lockPermissions, setLockPermissions] = useState(false);
  const [unLockPermissions, setUnlockPermissions] = useState(false);
  const [isAddingToBundle, setIsAddingToBundle] = useState(false);
  const [isShowingPDFModal, setIsShowingPDFModal] = useState(false);
  const [submissionStatusLock, setSubmissionStatusLock] = useState('');
  const [isInBundle, setIsInBundle] = useState(false);
  const { hasPermission } = usePermission();
  const [lockHeadMessage, setLockHeadMessage] = useState('');
  const [isAuditHeader, setIsAuditHeader] = useState(false);
  const [isTask, setIsTask] = useState(false);
  const [isInvestigation, setIsInvestigation] = useState(false);
  const [actionLock, setActionLock] = useState('');
  const [childId, setChildId] = useState('');
  const [childTitle, setChildTitle] = useState('');

  //Obj for accessing open modal func in child
  const confirmLockUnlock = useRef({
    openModal: () => {},
    setOpenModal(func: () => void) {
      this.openModal = func;
    },
  });

  useEffect(() => {
    //Get Permissions
    let viewBundle = hasPermission(PERMISSIONS.BUNDLE_VIEW);
    let lockSubmissionPermission = hasPermission(PERMISSIONS.SUBMISSION_LOCK);
    let unLockSubmissionPermission = hasPermission(
      PERMISSIONS.SUBMISSION_UNLOCK,
    );
    setAddToBundlePermissions(viewBundle);
    setLockPermissions(lockSubmissionPermission);
    setUnlockPermissions(unLockSubmissionPermission);

    if (props.submissionType == 'submission') {
      let formSubmission = submissions.find(x =>
        x._id.equals(props.submissionId),
      );

      //Don't show add report action button for audit submission
      if (
        !formSubmission ||
        formSubmission.templateType.name == TemplateTypes.AUDIT
      )
        setIsAuditHeader(true);
      else if (formSubmission.templateType.name == TemplateTypes.TASK)
        setIsTask(true);
    } else if (props.submissionType == 'submissionTraining') {
      let formSubmission = submissionTrainings.find(x =>
        x._id.equals(props.submissionId),
      );

      //Don't show add report action button for audit submission
      if (
        !formSubmission ||
        formSubmission.templateType.name == TemplateTypes.AUDIT
      )
        setIsAuditHeader(true);
    }

    //GetSubmission
    const getSubm = async () => {
      let subStatusLock: string | undefined = undefined;

      if (props.submissionType == 'submission') {
        let sub = submissions.find(x => x._id.equals(props.submissionId));
        if (sub) {
          subStatusLock = sub.submissionStatusLock;
        }
      } else if (props.submissionType == 'submissionTraining') {
        let subTraining = submissionTrainings.find(x =>
          x._id.equals(props.submissionId),
        );
        if (subTraining) {
          subStatusLock = subTraining.submissionStatusLock;
        }
      }

      setSubmissionStatusLock(subStatusLock ?? LOCK_STATUS.UNLOCK);

      setIsInBundle(
        await existSubmissionInBundle(new ObjectId(props.submissionId)),
      );
      if (
        subStatusLock == LOCK_STATUS.LOCK ||
        subStatusLock == LOCK_STATUS.SUPERLOCK
      )
        setLockHeadMessage('Locked');
      else setLockHeadMessage('Unlocked');
    };

    getSubm();
  }, []);

  useEffect(() => {
    const getChild = async () => {
      let formSubmission = submissions.find(x =>
        x._id.equals(props.submissionId),
      );

      if (formSubmission?.templateType.name == TemplateTypes.INVESTIGATION) {
        setIsInvestigation(true);

        let subLinks = (await getSubmissionLinks())
          .filter(x => x.parentSubmissionId.equals(props.submissionId))
          .map(x => x.childSubmissionId.toHexString());

        let childSubmissions = submissions.find(
          x =>
            subLinks.includes(x._id.toHexString()) &&
            x.templateType.name == TemplateTypes.INCIDENT,
        );

        if (childSubmissions) {
          setChildId(childSubmissions._id.toHexString());

          let childSubmissionsTitle = await getChildTitle(childSubmissions);
          setChildTitle(childSubmissionsTitle);
        }
      }
    };

    getChild();
  }, [props.submissionId]);

  async function getChildTitle(child: Submission): Promise<string> {
    if (!child) return '';

    let template = (await getFilteredTemplateVersions()).find(x =>
      x._id.equals(child!.templateVersion!._id!),
    )!;

    let title = template.name ?? '';
    if (child.metadataJSON) {
      let meta = JSON.parse(child.metadataJSON);
      if (meta[METADATA_KEYS.TITLE]) title = meta[METADATA_KEYS.TITLE];
      if (meta[METADATA_KEYS.PROJECT_NAME])
        title += ' at ' + meta[METADATA_KEYS.PROJECT_NAME];
    }

    return title;
  }

  const addToBundle = () => {
    setIsShowingActionsModal(false);
    setIsAddingToBundle(true);
  };

  const renderAddToBundleAction = () => {
    let addToBundleDisabled = false;
    let isDraft = false;

    if (isAuditHeader) return <></>;

    if (!addToBundlePermissions) addToBundleDisabled = true;

    //DRAFT reports or tasks in SUBMITTED cannot be locked/ added to bundle
    //Only SUBMITTED reports can be locked/added to bundle
    //Only Tasks that are COMPLETED or DISMISSED can be locked/added to bundle
    if (
      props.submissionStatus?.toLowerCase() ===
        SubmissionStatuses.DRAFT.toLowerCase() ||
      (props.submissionStatus?.toLowerCase() ===
        SubmissionStatuses.SUBMITTED.toLowerCase() &&
        isTask)
    ) {
      addToBundleDisabled = true;
      isDraft = true;
    }

    return (
      <View style={{ marginVertical: 0, paddingBottom: 0 }}>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            { alignSelf: 'center', marginTop: 14, width: '100%' },
            (pressed || addToBundleDisabled) && {
              opacity: 0.8,
            },
          ]}
          onPress={() => addToBundle()}
          disabled={addToBundleDisabled}>
          <Text style={CommonStyleSheet.greenButtonText}>
            Add Report To Bundle
          </Text>
        </Pressable>
        {!addToBundlePermissions && (
          <Text style={ControlsStyleSheet.error}>
            You do not have permissions to add reports to bundles
          </Text>
        )}
        {isDraft && (
          <Text style={ControlsStyleSheet.error}>
            You can only add submitted reports to a bundle
          </Text>
        )}
      </View>
    );
  };

  const closeAddToBundle = async () => {
    setIsAddingToBundle(false);
    saveSubmissionStatusLock();
  };

  const lockUnlockConfirmation = (lockAction: string) => {
    setActionLock(lockAction);
    confirmLockUnlock.current.openModal();
  };

  const renderLockUnlockConfirmation = () => {
    let messageText = '';

    if (isInvestigation) {
      messageText =
        actionLock == LOCK_STATUS.LOCK
          ? 'Opening will make this investigation editable.'
          : 'Closing will make this investigation uneditable. Only FIRS Admins can open.';
    } else {
      messageText =
        actionLock == LOCK_STATUS.LOCK
          ? 'Unlocking will make this report editable.'
          : 'Locking will make this report uneditable. Only FIRS Admins can unlock.';
    }
    return (
      <ConfirmModal
        title={
          isInvestigation ? 'Close/Open Investigation' : 'Lock/Unlock Report'
        }
        message={messageText}
        cancelClick={() => {
          setIsShowingActionsModal(false);
        }}
        cancelText="Cancel"
        okClick={() => {
          saveSubmissionStatusLock();
        }}
        okText={actionLock}
        setOpenModal={(func: () => void) => {
          confirmLockUnlock.current.setOpenModal(func);
        }}
      />
    );
  };

  const saveSubmissionStatusLock = async () => {
    let newSubmissionStatusLock = '';
    if (submissionStatusLock == LOCK_STATUS.LOCK)
      newSubmissionStatusLock = LOCK_STATUS.UNLOCK;
    else newSubmissionStatusLock = LOCK_STATUS.LOCK;

    //Update status lock in submission
    if (props.submissionType == 'submission') {
      let realmSubmission = submissions.find(x =>
        x._id.equals(props.submissionId),
      );
      if (realmSubmission) {
        if (await existSubmissionInBundle(realmSubmission!._id))
          newSubmissionStatusLock = LOCK_STATUS.SUPERLOCK;

        realmSubmission!.submissionStatusLock = newSubmissionStatusLock;
        await upsertSubmission(realmSubmission);
      }
    } else if (props.submissionType == 'submissionTraining') {
      let realmSubTraining = submissionTrainings.find(x =>
        x._id.equals(props.submissionId),
      );
      if (realmSubTraining) {
        if (await existSubmissionInBundle(realmSubTraining!._id))
          newSubmissionStatusLock = LOCK_STATUS.SUPERLOCK;

        realmSubTraining!.submissionStatusLock = newSubmissionStatusLock;
        await upsertSubmissionTraining(realmSubTraining);
      }
    }

    setSubmissionStatusLock(newSubmissionStatusLock);
    setIsShowingActionsModal(false);

    if (
      newSubmissionStatusLock == LOCK_STATUS.LOCK ||
      newSubmissionStatusLock == LOCK_STATUS.SUPERLOCK
    )
      setLockHeadMessage('Locked');
    else setLockHeadMessage('Unlocked');
    if (props.onStatusLockChange)
      props.onStatusLockChange(newSubmissionStatusLock);

    if (isInvestigation) {
      let childSubmission = submissions.find(x => x._id.equals(childId));

      if (childSubmission) {
        childSubmission.submissionStatusLock = newSubmissionStatusLock;

        let meta = null;

        if (childSubmission.metadataJSON)
          meta = JSON.parse(childSubmission.metadataJSON);

        if (meta === null) meta = {};

        meta[METADATA_KEYS.INVESTIGATION_CLOSED] =
          newSubmissionStatusLock == LOCK_STATUS.LOCK ||
          newSubmissionStatusLock == LOCK_STATUS.SUPERLOCK
            ? 'Yes'
            : 'No';

        childSubmission.metadataJSON = JSON.stringify(meta);

        await upsertSubmission(childSubmission);
      }
    }
  };

  async function goToSubmission(submissionId: string): Promise<void> {
    if (props.navigation)
      props.navigation.push(Navigation.DYNAMICFORM, {
        submissionId: submissionId,
      });
  }

  const renderChildRecord = (): React.ReactElement => {
    if (!childTitle) return <React.Fragment></React.Fragment>;

    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Pressable
          style={({ pressed }) => [
            pressed && {
              opacity: 0.8,
            },
            {
              backgroundColor: Colors.white,
              borderRadius: 6,
              paddingHorizontal: 12,
              paddingTop: 6,
              paddingBottom: 4,
              marginLeft: 56,
              marginVertical: 6,
            },
          ]}
          onPress={() => goToSubmission(childId)}>
          <Text style={[ControlsStyleSheet.label, { marginVertical: 0 }]}>
            {childTitle}
          </Text>
        </Pressable>
      </View>
    );
  };

  const renderLockUnlockAction = () => {
    let isDraft = true;
    let isTask = false;

    if (
      (props.submissionType != 'submission' &&
        props.submissionType != 'submissionTraining') ||
      isAuditHeader
    ) {
      return <></>;
    }

    //Check if it is task to check the status
    if (props.formName == 'Task') {
      isDraft =
        props.taskStatus?.toLowerCase() !=
          SubmissionStatuses.COMPLETE.toLowerCase() &&
        props.taskStatus?.toLowerCase() !=
          SubmissionStatuses.DISMISSED.toLowerCase();
      isTask = true;
    } else {
      isTask = false;
      isDraft =
        props.submissionStatus?.toLowerCase() !==
        SubmissionStatuses.SUBMITTED.toLowerCase();
    }

    let lockUnlockButtonText = '';
    let isButtonDisabled = false;
    let lockMessage = '';

    if (isDraft) {
      lockUnlockButtonText = isInvestigation ? 'Close' : LOCK_STATUS.LOCK;
      isButtonDisabled = true;

      if (isInvestigation)
        lockMessage = 'Investigation needs to be submitted before closing';
      else if (isTask)
        lockMessage = 'Task needs to be completed or dismissed before locking';
      else lockMessage = 'Report needs to be submitted before locking';
    } else if (submissionStatusLock == LOCK_STATUS.SUPERLOCK) {
      lockUnlockButtonText = isInvestigation ? 'Open' : LOCK_STATUS.UNLOCK;
      isButtonDisabled = true;

      if (isInvestigation)
        lockMessage = 'You cannot close it because it is part of a bundle';
      else lockMessage = 'You cannot unlock it because it is part of a bundle';
    } else if (submissionStatusLock == LOCK_STATUS.LOCK) {
      lockUnlockButtonText = isInvestigation ? 'Open' : LOCK_STATUS.UNLOCK;
      isButtonDisabled = !unLockPermissions;
      lockMessage = 'You do not have permission to unlock reports';

      if (isInvestigation)
        lockMessage = 'You do not have permission to re-open an investigation';
      else lockMessage = 'You do not have permission to unlock reports';
    } else if (submissionStatusLock == LOCK_STATUS.UNLOCK) {
      lockUnlockButtonText = isInvestigation ? 'Close' : LOCK_STATUS.LOCK;
      isButtonDisabled = !lockPermissions;
      lockMessage = 'You do not have permission to lock reports';

      if (isInvestigation)
        lockMessage = 'You do not have permission to close an investigation';
      else lockMessage = 'You do not have permission to lock reports';
    }

    return (
      <View style={{ marginVertical: 0, paddingBottom: 0 }}>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            { alignSelf: 'center', marginTop: 12, width: '100%' },
            (pressed || isButtonDisabled) && {
              opacity: 0.8,
            },
          ]}
          onPress={() => lockUnlockConfirmation(lockUnlockButtonText)}
          disabled={isButtonDisabled}>
          <Text style={CommonStyleSheet.greenButtonText}>
            {lockUnlockButtonText}
          </Text>
        </Pressable>
        {isButtonDisabled && (
          <Text style={ControlsStyleSheet.error}>{lockMessage}</Text>
        )}
      </View>
    );
  };

  const renderPrintAction = () => {
    return (
      <View>
        <Pressable
          style={({ pressed }) => [
            CommonStyleSheet.greenButton,
            { alignSelf: 'center', marginTop: 12, width: '100%' },
            pressed && {
              opacity: 0.8,
            },
          ]}
          onPress={() => {
            setIsShowingPDFModal(true);
          }}>
          <Text style={CommonStyleSheet.greenButtonText}>Print</Text>
        </Pressable>
      </View>
    );
  };

  const renderPDFModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingPDFModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Download Documents
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setIsShowingPDFModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 24 }}>
              <View style={ControlsStyleSheet.attachmentsContainer}>
                {isShowingPDFModal && (
                  <DynamicPDF
                    submissionId={props.submissionId}
                    type={props.submissionType ?? 'submission'}></DynamicPDF>
                )}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  const renderActionsModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingActionsModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <Pressable
          style={[
            ControlsStyleSheet.modalBackground,
            {
              //@ts-ignore
              cursor: 'auto',
            },
          ]}
          onPress={() => setIsShowingActionsModal(false)}>
          <View
            style={{
              backgroundColor: Colors.white,
              alignSelf: 'flex-end',
              borderRadius: 20,
              marginTop: 110,
              marginRight: 10,
              paddingHorizontal: 20,
              paddingBottom: 15,
            }}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                More Actions
              </Text>
            </View>
            {renderPrintAction()}
            {renderAddToBundleAction()}
            {renderLockUnlockAction()}
          </View>
        </Pressable>
      </Modal>
    );
  };

  return (
    <View style={DynamicPageStyleSheet.header}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View>
          {renderChildRecord()}
          <View
            style={{
              flexDirection: 'row',
              alignSelf: 'flex-start',
              gap: 8,
              paddingLeft: 20,
            }}>
            {props.formIcon && (
              <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  props.formIcon,
                )}`}
                style={{
                  width: 28,
                  height: 28,
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              />
            )}
            <Text style={[DynamicPageStyleSheet.headerText, { padding: 0 }]}>
              {props.formName}
            </Text>
          </View>
        </View>
        <View style={DynamicPageStyleSheet.closeFormIconContainer}>
          <Pressable
            onPress={props.closePressed}
            style={({ pressed }) => [
              pressed && {
                opacity: 0.4,
              },
            ]}>
            <Icon icon={'close-circle'} color={Colors.white} size={24} />
          </Pressable>
          {props.showMoreActions && (
            <Pressable
              style={({ pressed }) => [
                { marginTop: 24, marginBottom: 30 },
                pressed && {
                  opacity: 0.4,
                },
              ]}
              onPress={() => setIsShowingActionsModal(true)}>
              <Icon icon={'more'} color={Colors.white} size={20} />
            </Pressable>
          )}
        </View>
      </View>
      {lockHeadMessage && !isAuditHeader ? (
        <Text style={[DynamicPageStyleSheet.formInfo, { marginBottom: 4 }]}>
          {isInvestigation ? (
            <React.Fragment>
              {submissionStatusLock == LOCK_STATUS.LOCK ||
              submissionStatusLock == LOCK_STATUS.SUPERLOCK
                ? 'Closed'
                : 'Open'}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {submissionStatusLock == LOCK_STATUS.LOCK ||
              submissionStatusLock == LOCK_STATUS.SUPERLOCK
                ? 'Locked'
                : 'Unlocked'}
            </React.Fragment>
          )}
        </Text>
      ) : null}
      {props.creator && props.creatorLabel ? (
        <Text style={DynamicPageStyleSheet.formInfo}>
          {props.creatorLabel + ' ' + props.creator}
        </Text>
      ) : null}
      {props.updatedBy && props.updatedLabel ? (
        <Text style={[DynamicPageStyleSheet.formInfo, { marginTop: 4 }]}>
          {props.updatedLabel + ' ' + props.updatedBy}
        </Text>
      ) : null}
      {props.pageCount > 1 && (
        <ProgressBar
          pageCount={props.pageCount}
          currentPage={props.currentPage}
          pagePressed={props.pagePressed}
          validations={props.validations}
          showErrors={props.showErrors}
        />
      )}
      {renderActionsModal()}
      {renderLockUnlockConfirmation()}
      {props.showMoreActions && (
        <AddReportToBundle
          submissionId={props.submissionId}
          submissionType={props.submissionType}
          formName={props.formName}
          visible={isAddingToBundle}
          onClose={() => closeAddToBundle()}
        />
      )}

      {renderPDFModal()}
    </View>
  );
};

export default FormHeader;
